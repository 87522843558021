var I = Object.defineProperty;
var P = (n, s, t) => s in n ? I(n, s, { enumerable: !0, configurable: !0, writable: !0, value: t }) : n[s] = t;
var b = (n, s, t) => P(n, typeof s != "symbol" ? s + "" : s, t);
const N = {
  "#": { pattern: /[0-9]/ },
  "@": { pattern: /[a-zA-Z]/ },
  "*": { pattern: /[a-zA-Z0-9]/ }
}, R = (n, s, t) => n.replaceAll(s, "").replace(t, ".").replace("..", ".").replace(/[^.\d]/g, ""), C = (n, s, t) => {
  var e;
  return new Intl.NumberFormat(((e = t.number) == null ? void 0 : e.locale) ?? "en", {
    minimumFractionDigits: n,
    maximumFractionDigits: s,
    roundingMode: "trunc"
  });
}, F = (n, s = !0, t) => {
  var A, v, g, d;
  const e = ((A = t.number) == null ? void 0 : A.unsigned) !== !0 && n.startsWith("-") ? "-" : "", a = ((v = t.number) == null ? void 0 : v.fraction) ?? 0;
  let r = C(0, a, t);
  const h = r.formatToParts(1000.12), u = ((g = h.find((o) => o.type === "group")) == null ? void 0 : g.value) ?? " ", f = ((d = h.find((o) => o.type === "decimal")) == null ? void 0 : d.value) ?? ".", i = R(n, u, f);
  if (Number.isNaN(parseFloat(i))) return e;
  const p = i.split(".");
  if (p[1] != null && p[1].length >= 1) {
    const o = p[1].length <= a ? p[1].length : a;
    r = C(o, a, t);
  }
  let l = r.format(parseFloat(i));
  return s ? a > 0 && i.endsWith(".") && !i.slice(0, -1).includes(".") && (l += f) : l = R(l, u, f), e + l;
};
class T {
  constructor(s = {}) {
    b(this, "opts", {});
    b(this, "memo", /* @__PURE__ */ new Map());
    const t = { ...s };
    if (t.tokens != null) {
      t.tokens = t.tokensReplace ? { ...t.tokens } : { ...N, ...t.tokens };
      for (const e of Object.values(t.tokens))
        typeof e.pattern == "string" && (e.pattern = new RegExp(e.pattern));
    } else
      t.tokens = N;
    Array.isArray(t.mask) && (t.mask.length > 1 ? t.mask = [...t.mask].sort((e, a) => e.length - a.length) : t.mask = t.mask[0] ?? ""), t.mask === "" && (t.mask = null), this.opts = t;
  }
  masked(s) {
    return this.process(s, this.findMask(s));
  }
  unmasked(s) {
    return this.process(s, this.findMask(s), !1);
  }
  isEager() {
    return this.opts.eager === !0;
  }
  isReversed() {
    return this.opts.reversed === !0;
  }
  completed(s) {
    const t = this.findMask(s);
    if (this.opts.mask == null || t == null) return !1;
    const e = this.process(s, t).length;
    return typeof this.opts.mask == "string" ? e >= this.opts.mask.length : e >= t.length;
  }
  findMask(s) {
    const t = this.opts.mask;
    if (t == null)
      return null;
    if (typeof t == "string")
      return t;
    if (typeof t == "function")
      return t(s);
    const e = this.process(s, t.slice(-1).pop() ?? "", !1);
    return t.find((a) => this.process(s, a, !1).length >= e.length) ?? "";
  }
  escapeMask(s) {
    const t = [], e = [];
    return s.split("").forEach((a, r) => {
      a === "!" && s[r - 1] !== "!" ? e.push(r - e.length) : t.push(a);
    }), { mask: t.join(""), escaped: e };
  }
  process(s, t, e = !0) {
    if (this.opts.number != null) return F(s, e, this.opts);
    if (t == null) return s;
    const a = `v=${s},mr=${t},m=${e ? 1 : 0}`;
    if (this.memo.has(a)) return this.memo.get(a);
    const { mask: r, escaped: h } = this.escapeMask(t), u = [], f = this.opts.tokens != null ? this.opts.tokens : {}, i = this.isReversed() ? -1 : 1, p = this.isReversed() ? "unshift" : "push", l = this.isReversed() ? 0 : r.length - 1, A = this.isReversed() ? () => o > -1 && c > -1 : () => o < r.length && c < s.length, v = (k) => !this.isReversed() && k <= l || this.isReversed() && k >= l;
    let g, d = -1, o = this.isReversed() ? r.length - 1 : 0, c = this.isReversed() ? s.length - 1 : 0, E = !1;
    for (; A(); ) {
      const k = r.charAt(o), m = f[k], y = (m == null ? void 0 : m.transform) != null ? m.transform(s.charAt(c)) : s.charAt(c);
      if (!h.includes(o) && m != null ? (y.match(m.pattern) != null ? (u[p](y), m.repeated ? (d === -1 ? d = o : o === l && o !== d && (o = d - i), l === d && (o -= i)) : m.multiple && (E = !0, o -= i), o += i) : m.multiple ? E && (o += i, c -= i, E = !1) : y === g ? g = void 0 : m.optional && (o += i, c -= i), c += i) : (e && !this.isEager() && u[p](k), y === k && !this.isEager() ? c += i : g = k, this.isEager() || (o += i)), this.isEager())
        for (; v(o) && (f[r.charAt(o)] == null || h.includes(o)); ) {
          if (e) {
            if (u[p](r.charAt(o)), s.charAt(c) === r.charAt(o)) {
              o += i, c += i;
              continue;
            }
          } else r.charAt(o) === s.charAt(c) && (c += i);
          o += i;
        }
    }
    return this.memo.set(a, u.join("")), this.memo.get(a);
  }
}
const w = (n) => JSON.parse(n.replaceAll("'", '"')), x = (n, s = {}) => {
  const t = { ...s };
  n.dataset.maska != null && n.dataset.maska !== "" && (t.mask = W(n.dataset.maska)), n.dataset.maskaEager != null && (t.eager = M(n.dataset.maskaEager)), n.dataset.maskaReversed != null && (t.reversed = M(n.dataset.maskaReversed)), n.dataset.maskaTokensReplace != null && (t.tokensReplace = M(n.dataset.maskaTokensReplace)), n.dataset.maskaTokens != null && (t.tokens = O(n.dataset.maskaTokens));
  const e = {};
  return n.dataset.maskaNumberLocale != null && (e.locale = n.dataset.maskaNumberLocale), n.dataset.maskaNumberFraction != null && (e.fraction = parseInt(n.dataset.maskaNumberFraction)), n.dataset.maskaNumberUnsigned != null && (e.unsigned = M(n.dataset.maskaNumberUnsigned)), (n.dataset.maskaNumber != null || Object.values(e).length > 0) && (t.number = e), t;
}, M = (n) => n !== "" ? !!JSON.parse(n) : !0, W = (n) => n.startsWith("[") && n.endsWith("]") ? w(n) : n, O = (n) => {
  if (n.startsWith("{") && n.endsWith("}"))
    return w(n);
  const s = {};
  return n.split("|").forEach((t) => {
    const e = t.split(":");
    s[e[0]] = {
      pattern: new RegExp(e[1]),
      optional: e[2] === "optional",
      multiple: e[2] === "multiple",
      repeated: e[2] === "repeated"
    };
  }), s;
};
class V {
  constructor(s, t = {}) {
    b(this, "items", /* @__PURE__ */ new Map());
    b(this, "eventAbortController");
    b(this, "onInput", (s) => {
      if (s instanceof CustomEvent && s.type === "input" && !s.isTrusted && !s.bubbles)
        return;
      const t = s.target, e = this.items.get(t), a = "inputType" in s && s.inputType.startsWith("delete"), r = e.isEager(), h = a && r && e.unmasked(t.value) === "" ? "" : t.value;
      this.fixCursor(t, a, () => this.setValue(t, h));
    });
    this.options = t, this.eventAbortController = new AbortController(), this.init(this.getInputs(s));
  }
  update(s = {}) {
    this.options = { ...s }, this.init(Array.from(this.items.keys()));
  }
  updateValue(s) {
    s.value !== "" && s.value !== this.processInput(s).masked && this.setValue(s, s.value);
  }
  destroy() {
    this.eventAbortController.abort(), this.items.clear();
  }
  init(s) {
    const t = this.getOptions(this.options);
    for (const e of s) {
      if (!this.items.has(e)) {
        const { signal: r } = this.eventAbortController;
        e.addEventListener("input", this.onInput, { capture: !0, signal: r });
      }
      const a = new T(x(e, t));
      this.items.set(e, a), queueMicrotask(() => this.updateValue(e)), e.selectionStart === null && a.isEager() && console.warn("Maska: input of `%s` type is not supported", e.type);
    }
  }
  getInputs(s) {
    return typeof s == "string" ? Array.from(document.querySelectorAll(s)) : "length" in s ? Array.from(s) : [s];
  }
  getOptions(s) {
    const { onMaska: t, preProcess: e, postProcess: a, ...r } = s;
    return r;
  }
  fixCursor(s, t, e) {
    const a = s.selectionStart, r = s.value;
    if (e(), a === null || a === r.length && !t) return;
    const h = s.value, u = r.slice(0, a), f = h.slice(0, a), i = this.processInput(s, u).unmasked, p = this.processInput(s, f).unmasked;
    let l = a;
    u !== f && (l += t ? h.length - r.length : i.length - p.length), s.setSelectionRange(l, l);
  }
  setValue(s, t) {
    const e = this.processInput(s, t);
    s.value = e.masked, this.options.onMaska != null && (Array.isArray(this.options.onMaska) ? this.options.onMaska.forEach((a) => a(e)) : this.options.onMaska(e)), s.dispatchEvent(new CustomEvent("maska", { detail: e })), s.dispatchEvent(new CustomEvent("input", { detail: e.masked }));
  }
  processInput(s, t) {
    const e = this.items.get(s);
    let a = t ?? s.value;
    this.options.preProcess != null && (a = this.options.preProcess(a));
    let r = e.masked(a);
    return this.options.postProcess != null && (r = this.options.postProcess(r)), {
      masked: r,
      unmasked: e.unmasked(a),
      completed: e.completed(a)
    };
  }
}
export {
  T as Mask,
  V as MaskInput,
  N as tokens
};
