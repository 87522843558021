import {
  Address,
  addressTemplate,
  Choice,
  currencyFormat,
  emailTemplate,
  html,
  numberFormat,
  parseForm,
  personFullName,
  Phone,
  phoneTemplate,
  render,
  reportGridColumnDefTypes,
  reportLimits,
  userDateFormat
} from '@cumu/shared';
import { defaultLocale, loc_no, loc_open, loc_yes } from '@cumu/strings';
import { target } from '@github/catalyst';
import type AgGrid from 'ag-grid-enterprise';
import type { FormBehaviorElement } from '../forms/behavior-element';
import { getLocale } from '../locale';
import { controller } from './controller';

declare const AG_GRID_LICENSE_KEY: string;
declare const agGrid: typeof AgGrid;

const locale = getLocale();

const datetimeFormat = new Intl.DateTimeFormat(defaultLocale, {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
});

const columnTypes: Record<string, AgGrid.ColTypeDef> = {
  [reportGridColumnDefTypes.single_choice]: {
    valueFormatter: ({ value, colDef }) => {
      if (typeof value === 'number') {
      } else if (typeof value === 'boolean') {
        value = value ? 1 : 0;
      } else {
        return value;
      }
      const choices: Choice[] | undefined = colDef?.context?.choices;
      return choices?.find(c => c.choice_id === value)?.label;
    }
  },
  [reportGridColumnDefTypes.multiple_choice]: {
    valueFormatter: ({ value, colDef }) => {
      if (typeof value !== 'number') {
        return value;
      }
      const choices: Choice[] | undefined = colDef?.context?.choices;
      return choices
        ?.filter(c => ((2 ** c.choice_id) & value) !== 0)
        .map(c => c.label)
        .join(', ');
    }
  },
  [reportGridColumnDefTypes.boolean]: {
    valueFormatter: ({ value }) => {
      if (typeof value !== 'boolean') {
        return value;
      }
      return value ? loc_yes[locale] : loc_no[locale];
    }
  },
  [reportGridColumnDefTypes.date]: {
    valueFormatter: ({ value }) => {
      if (typeof value !== 'string' || value === '') {
        return value;
      }
      if (value.length === 10) {
        const ds = value.split('-').map(s => parseInt(s));
        ds[1] = ds[1] - 1; // js month is zero-based month
        const d = new Date(ds[0], ds[1], ds[2]);
        return userDateFormat.format(d);
      }
      const d = new Date(value + (value.endsWith('Z') ? '' : 'Z'));
      return datetimeFormat.format(d);
    }
  },
  [reportGridColumnDefTypes.decimal]: {
    valueFormatter: ({ value }) => {
      if (typeof value !== 'number') {
        return value;
      }
      return numberFormat.format(value);
    }
  },
  [reportGridColumnDefTypes.currency]: {
    valueFormatter: ({ value }) => {
      if (typeof value !== 'number') {
        return value;
      }
      return currencyFormat.format(value);
    }
  },
  [reportGridColumnDefTypes.int]: {
    valueFormatter: ({ value }) => {
      if (typeof value !== 'number') {
        return value;
      }
      // hack: don't use commas when the int might be a year
      if (value >= 1900 || value < 2100) {
        return value;
      }
      return numberFormat.format(value);
    }
  },
  [reportGridColumnDefTypes.row_href]: {
    cellRenderer: ({ value, colDef }: AgGrid.ICellRendererParams) => {
      const menuItems: { title: string; path: string }[] | undefined =
        colDef?.cellRendererParams?.menuItems;
      const a = document.createElement('a');
      a.className = 'btn btn-sm';
      a.href = value;
      a.textContent = colDef?.cellRendererParams?.title ?? loc_open[locale];
      if (!menuItems?.length) {
        a.style.marginTop = '-6px';
        return a;
      }
      const group = document.createElement('div');
      group.className = 'BtnGroup d-flex';
      a.className += ' BtnGroup-item';
      group.append(a);
      const details = document.createElement('details');
      details.className =
        'BtnGroup-parent dropdown details-reset details-overlay';
      const summary = document.createElement('summary');
      summary.className = 'btn btn-sm BtnGroup-item';
      const span = document.createElement('span');
      span.className = 'sr-only';
      span.textContent = 'Toggle Dropdown';
      summary.append(span);
      const caret = document.createElement('div');
      caret.className = 'dropdown-caret ml-0';
      summary.append(caret);
      details.append(summary);
      const menu = document.createElement('details-menu');
      menu.className = 'dropdown-menu dropdown-menu-se anim-scale-in';
      menu.role = 'menu';
      menuItems.forEach(({ title, path }) => {
        const item = document.createElement('a');
        item.className = 'dropdown-item';
        item.role = 'menuitem';
        item.href = value + path;
        item.textContent = title;
        menu.append(item);
      });
      details.append(menu);
      group.append(details);
      group.style.marginTop = '2px';
      return group;
    }
  },
  [reportGridColumnDefTypes.composite_address]: {
    cellRenderer: ({ colDef, data: row }: AgGrid.ICellRendererParams) => {
      const map = colDef!.context.composite_address_map;
      const address: Partial<Address> = {
        street_address: row[map['street_address']],
        sub_address: row[map['sub_address']],
        city: row[map['city']],
        region_abbr: row[map['region_abbr']],
        subregion: row[map['subregion']],
        postal_code: row[map['postal_code']],
        postal_code_ext: row[map['postal_code_ext']]
      };
      return render(addressTemplate(address, true));
    }
  },
  [reportGridColumnDefTypes.composite_phone]: {
    cellRenderer: ({ colDef, data: row }: AgGrid.ICellRendererParams) => {
      const map = colDef!.context.composite_phone_map;
      const phone: Pick<Phone, 'type' | 'number' | 'extension'> = {
        type: row[map['type']],
        number: row[map['number']],
        extension: row[map['extension']]
      };
      if (phone.type && phone.number) {
        return render(phoneTemplate(phone, true));
      }
      return '';
    }
  },
  [reportGridColumnDefTypes.composite_email]: {
    cellRenderer: ({ colDef, data: row }: AgGrid.ICellRendererParams) => {
      const map = colDef!.context.composite_email_map;
      const email = {
        type: row[map['type'] ?? 'zzz-nothing-matches-this-key'],
        email: row[map['email']]
      };
      if (email.type && email.email) {
        return render(emailTemplate(email, true, false));
      }
      if (email.email) {
        const a = document.createElement('a');
        a.href = `mailto:${email.email}`;
        a.className = 'Link--primary';
        a.textContent = email.email;
        return a;
      }
      return '';
    }
  },
  [reportGridColumnDefTypes.composite_full_name]: {
    cellRenderer: ({ colDef, data: row }: AgGrid.ICellRendererParams) => {
      const map = colDef!.context.composite_full_name_map;
      const person = {
        last_name: row[map['last_name']],
        first_name: row[map['first_name']],
        middle_name: row[map['middle_name']]
      };
      return personFullName(person);
    }
  },
  [reportGridColumnDefTypes.value_map]: {
    valueFormatter: ({ value, colDef }) => {
      const map = colDef!.context.value_map;
      return map[value] ?? value;
    }
  }
};

@controller('report-grid')
class ReportGridElement extends HTMLElement {
  @target declare optionsScript: HTMLScriptElement;
  @target declare keyScript: HTMLScriptElement;
  @target declare gridContainer: HTMLDivElement;
  @target declare parametersForm: HTMLFormElement;
  declare grid: AgGrid.GridApi;

  connectedCallback() {
    const options: AgGrid.GridOptions = JSON.parse(
      this.optionsScript.textContent!
    );
    const key = JSON.parse(this.keyScript.textContent!);
    this.optionsScript.remove();
    options.getContextMenuItems = ({}) => {
      return ['copy'];
    };
    options.popupParent = document.body;
    options.columnTypes = columnTypes;
    options.rowModelType = 'serverSide';
    options.skipHeaderOnAutoSize = true;
    options.suppressColumnVirtualisation = true;
    options.rowClassRules = {
      'color-bg-subtle': ({ rowIndex }) => rowIndex % 2 !== 0
    };
    options.defaultColDef = {
      headerComponentParams: {
        template: render(
          html`<div class="ag-cell-label-container" role="presentation">
            <span
              data-ref="eMenu"
              class="ag-header-icon ag-header-cell-menu-button"
              aria-hidden="true"
            ></span>
            <span
              data-ref="eFilterButton"
              class="ag-header-icon ag-header-cell-filter-button"
              aria-hidden="true"
            ></span>
            <div
              data-ref="eLabel"
              class="ag-header-cell-label"
              role="presentation"
            >
              <span data-ref="eText" class="ag-header-cell-text"></span>
              <span
                data-ref="eFilter"
                class="ag-header-icon ag-header-label-icon ag-filter-icon"
                aria-hidden="true"
              ></span>
              <span
                data-ref="eSortAsc"
                class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon"
                style="--ag-icon-size: 14px"
                aria-hidden="true"
              ></span>
              <span
                data-ref="eSortDesc"
                class="ag-header-icon ag-header-label-icon ag-sort-descending-icon"
                style="--ag-icon-size: 14px"
                aria-hidden="true"
              ></span>
              <span
                data-ref="eSortNone"
                class="ag-header-icon ag-header-label-icon ag-sort-none-icon"
                style="--ag-icon-size: 14px"
                aria-hidden="true"
              ></span>
              <span
                data-ref="eSortOrder"
                class="ag-sort-order"
                style="position: relative; bottom: -8px; left: -4px; font-size: 0.625rem"
                aria-hidden="true"
              ></span>
            </div>
          </div>`
        )
      }
    };
    options.serverSideDatasource = {
      getRows: async ({
        request: { startRow, endRow, sortModel },
        success,
        fail
      }) => {
        const url = this.getAttribute('row-source')!;
        const { parameter_values = {} } = parseForm<{
          parameter_values?: Record<string, string | number>;
        }>(new FormData(this.parametersForm));
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            startRow,
            endRow,
            sortModel: sortModel.slice(0, reportLimits.sorts),
            key,
            parameter_values
          })
        });
        if (!response.ok) {
          fail();
          return;
        }
        const rowData = await response.json();
        const rowCount = rowData[0]?.count ?? 0;
        success({ rowData, rowCount });
        this.dispatchEvent(
          new CustomEvent<number>('report-grid-row-count', {
            detail: rowCount,
            bubbles: true
          })
        );
      }
    };
    agGrid.LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);
    this.grid = agGrid.createGrid(this.gridContainer, options);
    this.grid.addEventListener('firstDataRendered', () => {
      setTimeout(() => {
        const columns = this.grid.getAllDisplayedColumns();
        const widths = columns.map(c => c.getActualWidth());
        const columnsWidth = widths.reduce((a, b) => a + b, 0);
        const gridWidth = this.clientWidth;
        if (columnsWidth > gridWidth - 16) {
          return;
        }
        const available = gridWidth - columnsWidth;
        const start = columns[0].getColId() === 'navigation' ? 1 : 0;
        const utilized = widths.slice(start).reduce((a, b) => a + b, 0);
        const newWidths: { key: string; newWidth: number }[] = [];
        for (let i = start; i < columns.length; i++) {
          const current = widths[i];
          const ratio = current / utilized;
          const newWidth = Math.min(
            current + available * ratio,
            Math.max(current, 500)
          );
          newWidths.push({ key: columns[i].getColId(), newWidth });
        }
        this.grid.setColumnWidths(newWidths);
      });
    });
  }

  disconnectedCallback() {
    this.grid?.destroy();
  }

  submitParameters(event: CustomEvent<FormData>) {
    event.preventDefault();
    (event.target as FormBehaviorElement).acceptChanges();
    this.grid?.refreshServerSide({ purge: true });
  }
}

declare global {
  interface Window {
    ReportGridElement: typeof ReportGridElement;
  }
  interface HTMLElementTagNameMap {
    'report-grid': ReportGridElement;
  }
}
