import {
  Locale,
  loc_administrative,
  loc_billing,
  loc_clients,
  loc_emergencyContact,
  loc_employer,
  loc_federalAgency,
  loc_humanResources,
  loc_insuranceCompany,
  loc_nextOfKin,
  loc_payor,
  loc_press,
  loc_primaryEmergencyContact,
  loc_stateAgency,
  loc_unknown
} from '@cumu/strings';
import { Address } from './address';
import { EntityWithContactInfo } from './contact-info';
import { Email } from './email';
import { Phone } from './phone';
import { SharedResource } from './resource';

export interface Contact extends SharedResource, EntityWithContactInfo {
  contact_id: string;
  parent_id: string;
  name: string;
  type: ContactType;
  description: string;
  emails?: Email[];
  phones?: Phone[];
  addresses?: Address[];
}

export const enum ContactType {
  Unknown = 4096,
  EmergencyContact = 1,
  Employer = 2,
  FederalAgency = 4,
  InsuranceCompany = 8,
  NextOfKin = 16,
  StateAgency = 32,
  PrimaryEmergencyContact = 1048576, // Math.pow(2, 20)

  Billing = 64,
  Administrative = 128,
  HumanResource = 256,
  Payor = 512,
  Patient = 1024,
  Press = 2048,

  Site = 262144,
  PrimarySite = 524288
}

export const personContactTypes: {
  id: ContactType;
  fhirCode: string;
  label: Record<Locale, string>;
}[] = [
  {
    id: ContactType.PrimaryEmergencyContact,
    fhirCode: 'C',
    label: loc_primaryEmergencyContact
  },
  {
    id: ContactType.EmergencyContact,
    fhirCode: 'C',
    label: loc_emergencyContact
  },
  { id: ContactType.NextOfKin, fhirCode: 'N', label: loc_nextOfKin },
  { id: ContactType.Employer, fhirCode: 'E', label: loc_employer },
  { id: ContactType.FederalAgency, fhirCode: 'F', label: loc_federalAgency },
  { id: ContactType.StateAgency, fhirCode: 'S', label: loc_stateAgency },
  {
    id: ContactType.InsuranceCompany,
    fhirCode: 'I',
    label: loc_insuranceCompany
  },
  { id: ContactType.Unknown, fhirCode: 'U', label: loc_unknown }
];

export const organizationContactTypes: {
  id: ContactType;
  fhirCode: string;
  label: Record<Locale, string>;
}[] = [
  {
    id: ContactType.Billing,
    fhirCode: 'BILL',
    label: loc_billing
  },
  {
    id: ContactType.Administrative,
    fhirCode: 'ADMIN',
    label: loc_administrative
  },
  { id: ContactType.HumanResource, fhirCode: 'HR', label: loc_humanResources },
  { id: ContactType.Payor, fhirCode: 'PAYOR', label: loc_payor },
  { id: ContactType.Patient, fhirCode: 'PATINF', label: loc_clients },
  { id: ContactType.Press, fhirCode: 'PRESS', label: loc_press }
];
