import { defaultLocale } from '@cumu/strings';
import { ExpressionReturnType } from '../expressions/common';
import { reportColumnIndexToIdentifierName } from '../expressions/reports';
import {
  issueOpenedIcon,
  organizationIcon,
  peopleIcon,
  personRolodex
} from '../icons';
import { activityStatusTitles, openActivityStatuses } from './activity';
import {
  EntityTypeName,
  Property,
  PropertyType,
  wellKnownProperties
} from './entity-type';
import { LookupItem } from './lookup-item';
import { hasRoleLevel } from './organization';
import { OrganizationSharedResource, SharedResource } from './resource';

export const reportPageSize = 5000;

export type ReportEntityType =
  | 'activity'
  | 'person'
  | 'user_organization'
  | 'organization_project';

export const reportEntityTypes: ReportEntityType[] = [
  'activity',
  'person',
  'user_organization',
  'organization_project'
];

export const reportEntityInfo: Record<
  ReportEntityType,
  {
    singular: string;
    plural: string;
    description: string;
    included: EntityTypeName[];
    icon: string;
    subqueries: {
      value:
        | 'grandparent-activity'
        | 'parent-activity'
        | 'child-activities'
        | 'person-activities'
        | 'sibling-activities'
        | 'assigned-activities'
        | 'created-activities'
        | 'contracted-activities';
      text: string;
    }[];
  }
> = {
  activity: {
    singular: 'Activity',
    plural: 'Activities',
    description: `Activity records, along with the associated person's demographics and contact information.`,
    included: ['person'],
    icon: issueOpenedIcon,
    subqueries: [
      { value: 'child-activities', text: 'Child activities' },
      { value: 'person-activities', text: `Person's activities` },
      { value: 'sibling-activities', text: 'Sibling activities' },
      { value: 'parent-activity', text: 'Parent activity' },
      { value: 'grandparent-activity', text: 'Grandparent activity' }
    ]
  },
  organization_project: {
    singular: 'Project organization',
    plural: 'Project organizations',
    description: `Organizations collaborating on projects you are participating in.`,
    included: [],
    icon: organizationIcon,
    subqueries: [
      { value: 'assigned-activities', text: 'Assigned activities' },
      { value: 'contracted-activities', text: 'Contracted activities' },
      { value: 'created-activities', text: 'Created activities' }
    ]
  },
  person: {
    singular: 'Person',
    plural: 'People',
    description: `Demographics, addresses, emails, and phones.`,
    included: [],
    icon: peopleIcon,
    subqueries: [{ value: 'child-activities', text: 'Activities' }]
  },
  user_organization: {
    singular: 'Organization Member',
    plural: 'Organization Members',
    description: `Members of your organization and project collaborators from outside organizations.`,
    included: [],
    icon: personRolodex,
    subqueries: [
      { value: 'assigned-activities', text: 'Assigned activities' },
      { value: 'created-activities', text: 'Created activities' }
    ]
  }
};

export const enum ReportSharedWith {
  None = 0,
  Organization = 1,
  Project = 2
}

export type ReportLayout = 'table'; // 'list';

export type ReportSortDirection = 'asc' | 'desc';

export type ReportFilterComparisonOperator =
  | '='
  | '<>'
  | '>'
  | '>='
  | '<'
  | '<=';

export type ReportFilterOperator =
  | ReportFilterComparisonOperator
  | 'in'
  | 'not in'
  | 'startswith'
  | 'notstartswith'
  | 'contains'
  | 'notcontains'
  | 'blank'
  | 'notblank';

export const operatorNames: Record<ReportFilterOperator, string> = {
  '=': 'Equal to',
  '<>': 'Not equal to',
  '<': 'Less than',
  '<=': 'Less than or equal to',
  '>': 'Greater than',
  '>=': 'Greater than or equal to',
  in: 'Any of',
  'not in': 'None of',
  startswith: 'Starts with',
  notstartswith: 'Does not start with',
  contains: 'Contains',
  notcontains: 'Does not contain',
  blank: 'Is blank/unanswered',
  notblank: 'Is not blank/unanswered'
};

export const dateOperatorNames: Record<ReportFilterOperator, string> =
  Object.assign({}, operatorNames, {
    '<': 'Before',
    '<=': 'On or before',
    '>': 'After',
    '>=': 'On or after'
  });

export const operators = /*@__PURE__*/ Object.keys(
  operatorNames
) as ReportFilterOperator[];

export const comparisonOperators: ReportFilterComparisonOperator[] = [
  '=',
  '<>',
  '>',
  '>=',
  '<',
  '<='
];

export function isComparisonOperator(
  op: string
): op is ReportFilterComparisonOperator {
  return comparisonOperators.includes(op as ReportFilterComparisonOperator);
}

export function compare(
  op: ReportFilterComparisonOperator,
  a: number | string,
  b: number | string
) {
  if (typeof a !== typeof b) {
    throw new Error(
      `Expected typeof a "${typeof a}" to match typeof "${typeof b}".`
    );
  }
  switch (op) {
    case '<':
      return a < b;
    case '<=':
      return a <= b;
    case '<>':
      return a !== b;
    case '=':
      return a === b;
    case '>':
      return a > b;
    case '>=':
      return a >= b;
    default:
      throw new Error(`Unexpected operator "${op}"`);
  }
}

export type ReportAggregateFunction =
  | 'count'
  | 'count-distinct'
  | 'sum'
  | 'min'
  | 'max'
  | 'avg'
  | 'stdev'
  | 'var'
  | 'last-created'
  | 'last-started'
  | 'last-ended';

export const aggregateFunctions: ReportAggregateFunction[] = [
  'count',
  'count-distinct',
  'sum',
  'min',
  'max',
  'avg',
  'stdev',
  'var',
  'last-created',
  'last-started',
  'last-ended'
];

export const aggregateFunctionResultType: Record<
  ReportAggregateFunction,
  'int' | 'decimal' | 'inherit-number' | 'inherit'
> = {
  count: 'int',
  'count-distinct': 'int',
  sum: 'inherit-number',
  min: 'inherit',
  max: 'inherit',
  avg: 'decimal',
  stdev: 'decimal',
  var: 'decimal',
  'last-created': 'inherit',
  'last-started': 'inherit',
  'last-ended': 'inherit'
};

export const aggregateFunctionSort: Record<
  'last-created' | 'last-started' | 'last-ended',
  string
> = {
  'last-created': '[create_datetime] desc',
  'last-started': '[start_date] desc',
  'last-ended': '[end_date] desc, [start_date] desc'
};

export const aggregateFunctionPropertyTypes: Record<
  ReportAggregateFunction,
  PropertyType[]
> = {
  count: [],
  'count-distinct': [
    'uniqueidentifier',
    'varchar100',
    'int',
    'bit',
    'currency',
    'decimal',
    'date'
  ],
  sum: ['int', 'currency', 'decimal'],
  avg: ['int', 'currency', 'decimal'],
  stdev: ['int', 'currency', 'decimal'],
  var: ['int', 'currency', 'decimal'],
  min: [
    'uniqueidentifier',
    'varchar100',
    'int',
    'bit',
    'currency',
    'decimal',
    'date'
  ],
  max: [
    'uniqueidentifier',
    'varchar100',
    'int',
    'bit',
    'currency',
    'decimal',
    'date'
  ],
  'last-created': [
    'uniqueidentifier',
    'varchar100',
    'int',
    'bit',
    'currency',
    'decimal',
    'date',
    'activity'
  ],
  'last-started': [
    'uniqueidentifier',
    'varchar100',
    'int',
    'bit',
    'currency',
    'decimal',
    'date',
    'activity'
  ],
  'last-ended': [
    'uniqueidentifier',
    'varchar100',
    'int',
    'bit',
    'currency',
    'decimal',
    'date',
    'activity'
  ]
};

export const aggregateFunctionNames: Record<ReportAggregateFunction, string> = {
  count: 'Count',
  'count-distinct': 'Distinct count',
  sum: 'Sum',
  min: 'Minimum',
  max: 'Maximum',
  avg: 'Average',
  stdev: 'Standard deviation',
  var: 'Variance',
  'last-created': 'Most recently created',
  'last-started': 'Most recently started',
  'last-ended': 'Most recently ended'
};

export const compositeProperties = [
  // custom:
  wellKnownProperties.activity.attachment_count,
  wellKnownProperties.activity.assignee_id,
  wellKnownProperties.activity.create_dow,
  wellKnownProperties.activity.subscribed,
  wellKnownProperties.activity.comment_create_user_id,
  wellKnownProperties.activity.comment_mentioned_user_id,
  wellKnownProperties.activity.primary_physical.is_precise,
  wellKnownProperties.activity.assignee_organization_primary_physical
    .is_precise,
  wellKnownProperties.activity.create_organization_primary_physical.is_precise,
  wellKnownProperties.person.primary_mailing.is_precise,
  wellKnownProperties.person.primary_physical.is_precise,
  wellKnownProperties.person.primary_mailing.is_precise,
  wellKnownProperties.user_organization.projects,
  wellKnownProperties.organization_project.primary_physical.is_precise,
  // composite:
  wellKnownProperties.activity.primary_physical.address,
  wellKnownProperties.activity.assignee_organization_primary_physical.address,
  wellKnownProperties.activity.create_organization_primary_physical.address,
  wellKnownProperties.activity.assignee_organization_primary_email.formatted,
  wellKnownProperties.activity.create_organization_primary_email.formatted,
  wellKnownProperties.activity.primary_phone.formatted,
  wellKnownProperties.activity.primary_email.formatted,
  wellKnownProperties.person.full_name,
  wellKnownProperties.person.primary_physical.address,
  wellKnownProperties.person.primary_mailing.address,
  wellKnownProperties.person.primary_phone.formatted,
  wellKnownProperties.person.primary_email.formatted,
  wellKnownProperties.user_organization.primary_phone.formatted,
  wellKnownProperties.organization_project.primary_phone.formatted,
  wellKnownProperties.organization_project.primary_physical.address
];

export function getGroupingOptions(
  column:
    | Pick<StandardReportColumn | ExpressionReportColumn, 'type'>
    | Pick<SubqueryReportColumn, 'type' | 'aggregate'>,
  property: Pick<
    Property,
    'property_id' | 'type' | 'choice_type' | 'response'
  > | null,
  expressionType: ExpressionReturnType | null
): ('none' | 'group' | ReportAggregateFunction)[] {
  // no grouping for composite props or properties whose choice type is not 'single' or 'none'.
  if (
    compositeProperties.includes(property?.property_id ?? NaN) ||
    (property?.response &&
      !(
        ['none', 'single'].includes(property.choice_type) ||
        property.type === 'activity'
      ))
  ) {
    return ['none'];
  }
  // grouping only for single choice properties.
  if (property?.choice_type === 'single' || property?.type === 'activity') {
    return ['none', 'group'];
  }
  if (
    (property && column.type === 'standard') ||
    (property &&
      column.type === 'subquery' &&
      ['min', 'max', 'last-created', 'last-started', 'last-ended'].includes(
        column.aggregate
      ))
  ) {
    switch (property.type) {
      case 'date':
        return ['none', 'group', 'count', 'count-distinct', 'max', 'min'];
      case 'currency':
      case 'int':
      case 'decimal':
        return [
          'none',
          'group',
          'count',
          'count-distinct',
          'sum',
          'avg',
          'stdev',
          'var',
          'max',
          'min'
        ];
      case 'varchar100':
        return ['none', 'group', 'count', 'count-distinct', 'max', 'min'];
      case 'varcharmax':
        return ['none'];
      case 'bit':
        return ['none', 'group'];
      case 'uniqueidentifier':
        return ['none', 'group', 'count', 'count-distinct', 'max', 'min'];
      default:
        return ['none'];
    }
  } else if (column.type === 'subquery') {
    switch (column.aggregate) {
      case 'sum':
      case 'count':
      case 'count-distinct':
      case 'avg':
      case 'stdev':
      case 'var':
        return ['none', 'sum', 'avg', 'stdev', 'var', 'max', 'min'];
      default:
        return ['none'];
    }
  } else if (column.type === 'expression') {
    switch (expressionType) {
      case 'date':
      case 'datetime':
        return ['none', 'group', 'max', 'min'];
      case 'int':
      case 'decimal':
        return [
          'none',
          ...(expressionType === 'int' ? ['group' as 'group'] : []),
          'sum',
          'avg',
          'stdev',
          'var',
          'max',
          'min'
        ];
      case 'string':
        return ['none', 'group'];
      case 'boolean':
      default:
        return ['none'];
    }
  } else {
    return ['none'];
  }
}

export type BlankType = 'empty-string' | 'null' | 'null-or-empty-string';

export const blankTypes = /*@__PURE__*/ new Map<number, BlankType>([
  [wellKnownProperties.person.first_name, 'empty-string'],
  [wellKnownProperties.person.gender, 'null'],
  [wellKnownProperties.person.gender_identity, 'null'],
  [wellKnownProperties.person.sexual_orientation, 'null'],
  [wellKnownProperties.person.race, 'null'],
  [wellKnownProperties.person.ethnicity, 'null'],
  [wellKnownProperties.person.marital_status, 'null'],
  [wellKnownProperties.person.primary_language, 'null'],
  [wellKnownProperties.person.dob, 'null'],
  [wellKnownProperties.person.primary_physical.city, 'null-or-empty-string'],
  [
    wellKnownProperties.person.primary_physical.region_abbr,
    'null-or-empty-string'
  ],
  [
    wellKnownProperties.person.primary_physical.subregion,
    'null-or-empty-string'
  ],
  [
    wellKnownProperties.person.primary_physical.postal_code,
    'null-or-empty-string'
  ],
  [wellKnownProperties.person.ssn, 'null'],
  [wellKnownProperties.person.veteran_id, 'null'],
  [wellKnownProperties.person.medicaid_id, 'null'],
  [wellKnownProperties.person.agingis_id, 'null'],
  [wellKnownProperties.person.user_id, 'null'],
  [wellKnownProperties.activity.end_date, 'null'],
  [wellKnownProperties.activity.due_date, 'null'],
  [wellKnownProperties.activity.assignee_user_id, 'null'],
  [wellKnownProperties.activity.primary_physical.city, 'null-or-empty-string'],
  [
    wellKnownProperties.activity.primary_physical.region_abbr,
    'null-or-empty-string'
  ],
  [
    wellKnownProperties.activity.primary_physical.subregion,
    'null-or-empty-string'
  ],
  [
    wellKnownProperties.activity.primary_physical.postal_code,
    'null-or-empty-string'
  ],
  [
    wellKnownProperties.activity.assignee_organization_primary_physical.city,
    'null-or-empty-string'
  ],
  [
    wellKnownProperties.activity.assignee_organization_primary_physical
      .region_abbr,
    'null-or-empty-string'
  ],
  [
    wellKnownProperties.activity.assignee_organization_primary_physical
      .subregion,
    'null-or-empty-string'
  ],
  [
    wellKnownProperties.activity.assignee_organization_primary_physical
      .postal_code,
    'null-or-empty-string'
  ],
  [
    wellKnownProperties.activity.create_organization_primary_physical.city,
    'null-or-empty-string'
  ],
  [
    wellKnownProperties.activity.create_organization_primary_physical
      .region_abbr,
    'null-or-empty-string'
  ],
  [
    wellKnownProperties.activity.create_organization_primary_physical.subregion,
    'null-or-empty-string'
  ],
  [
    wellKnownProperties.activity.create_organization_primary_physical
      .postal_code,
    'null-or-empty-string'
  ],
  [wellKnownProperties.organization_project.classification, 'null'],
  [wellKnownProperties.organization_project.fein, 'null']
]);

export function getBlankType(property: {
  property_id: number;
  response?: boolean;
}): BlankType | undefined {
  if (property.response) {
    return 'null';
  }
  return blankTypes.get(property.property_id);
}

export const reportLimits = {
  parameters: 10,
  filters: 10,
  sorts: 4,
  columns: 26,
  inItems: 75,
  expressionMaxLength: 100,
  parameterSelectItems: 24,
  parameterNameMaxLength: 20,
  parameterLabelMaxLength: 20
};

export const reportWildcards = {
  me: {
    id: 'b0000000-0000-0000-0000-000000000000',
    title: '@Me'
  },
  myOrganization: {
    id: 'b0000000-0000-0000-0000-000000000001',
    title: '@My Organization'
  },
  createUser: {
    id: 'b0000000-0000-0000-0000-000000000002',
    title: '@Creator'
  },
  createOrganization: {
    id: 'b0000000-0000-0000-0000-000000000003',
    title: '@Creator Organization'
  },
  myOrganizationCity: {
    id: '@MyOrganizationCity',
    title: `@My Organization's City`
  },
  myOrganizationState: {
    id: '@MyOrganizationState',
    title: `@My Organization's State`
  },
  myOrganizationCounty: {
    id: '@MyOrganizationCounty',
    title: `@My Organization's County`
  },
  myOrganizationContracts: {
    id: '@MyOrganizationContracts',
    title: `@My Organization's Contracts`
  }
};

export function isReportReadOnly(
  report: Report,
  userOrganization: {
    user_id: string;
    role_id: string;
    organization_id: string;
  }
) {
  if (
    report.create_user_id === userOrganization.user_id &&
    report.create_organization_id === userOrganization.organization_id &&
    hasRoleLevel(userOrganization, 'Writer')
  ) {
    return false;
  }
  if (
    report.is_shared !== ReportSharedWith.None &&
    userOrganization.organization_id === report.create_organization_id &&
    hasRoleLevel(userOrganization, 'Admin')
  ) {
    return false;
  }
  return true;
}

export type ReportSubquery =
  | {
      type: 'subquery';
      association:
        | 'grandparent-activity'
        | 'parent-activity'
        | 'child-activities'
        | 'person-activities'
        | 'sibling-activities'
        | 'assigned-activities'
        | 'created-activities'
        | 'contracted-activities';
      aggregate: 'count';
      filters: StandardReportFilter[];
    }
  | {
      type: 'subquery';
      association:
        | 'grandparent-activity'
        | 'parent-activity'
        | 'child-activities'
        | 'person-activities'
        | 'sibling-activities'
        | 'assigned-activities'
        | 'created-activities'
        | 'contracted-activities';
      aggregate:
        | 'count-distinct'
        | 'sum'
        | 'min'
        | 'max'
        | 'avg'
        | 'stdev'
        | 'var'
        | 'last-created'
        | 'last-started'
        | 'last-ended';
      subquery_property_id: number;
      filters: StandardReportFilter[];
    };

export type ReportFilterValue = null | string | number | boolean | LookupItem[];

export type ReportFilter = StandardReportFilter | SubqueryReportFilter;

export interface StandardReportFilter {
  type: 'standard';
  property_id: number;
  operator: ReportFilterOperator;
  value: ReportFilterValue;
}

export type SubqueryReportFilter = ReportSubquery & {
  operator: ReportFilterOperator;
  value: ReportFilterValue;
};
export interface ReportSort {
  column_index: number;
  direction: ReportSortDirection;
}

export type ReportColumn =
  | StandardReportColumn
  | SubqueryReportColumn
  | ExpressionReportColumn;

export interface ReportColumnGrouping {
  type: 'none' | 'group' | ReportAggregateFunction;
}

export type ReportColumnSort = `${0 | 1 | 2 | 3}/${'asc' | 'desc'}`;

export function sequenceSorts(columns: ReportColumn[]) {
  const useGrouping = columns.some(c => c.grouping.type !== 'none');
  if (useGrouping) {
    for (const column of columns) {
      if (column.grouping.type === 'none') {
        column.sort = null;
      }
    }
  }
  columns
    .filter(x => x.sort)
    .sort((a, b) => +a.sort![0] - +b.sort![0])
    .slice(0, 4)
    .forEach((x, i) => {
      x.sort = `${i as 0 | 1 | 2 | 3}/${x.sort!.split('/')[1] as ReportSortDirection}`;
    });
  if (!columns.some(x => x.sort)) {
    const first = columns.find(x => !useGrouping || x.grouping.type !== 'none');
    if (first) {
      first.sort = '0/asc';
    }
  }
}

export const reportColumnSortOptions: {
  value: '' | ReportColumnSort;
  text: string;
  group?: string;
}[] = [
  { value: '', text: 'None' },
  { value: '0/asc', text: 'Ascending (1)', group: 'Primary sort' },
  { value: '0/desc', text: 'Descending (1)', group: 'Primary sort' },
  { value: '1/asc', text: 'Ascending (2)', group: 'Secondary sort' },
  { value: '1/desc', text: 'Descending (2)', group: 'Secondary sort' },
  { value: '2/asc', text: 'Ascending (3)', group: 'Third sort' },
  { value: '2/desc', text: 'Descending (3)', group: 'Third sort' },
  { value: '3/asc', text: 'Ascending (4)', group: 'Fourth sort' },
  { value: '3/desc', text: 'Descending (4)', group: 'Fourth sort' }
];

export interface ReportColumnBase {
  label?: string;
  grouping: ReportColumnGrouping;
  sort: ReportColumnSort | null;
}

export type StandardReportColumn = ReportColumnBase & {
  type: 'standard';
  property_id: number;
};

export type SubqueryReportColumn = ReportColumnBase & ReportSubquery;

export type ExpressionReportColumn = ReportColumnBase & {
  type: 'expression';
  expression: string;
};

export function reportColumnHeader(
  index: number,
  column: ReportColumn,
  entity_type: ReportEntityType,
  properties: Record<number, Property>
) {
  if (column.type === 'subquery' || column.type === 'expression') {
    return column.label || `Column ${reportColumnIndexToIdentifierName(index)}`;
  }

  const property = properties[column.property_id];
  return column.label || getPropertyLabelForReport(entity_type, property);
}

export const identifierRegex = /^\$[A-Z][A-Z0-9_]{0,18}$/;

export interface DeprecatedLiteralReportParameter {
  type: 'literal';
  name: string;
  label: string;
  data_type: 'date';
  value: string;
}

export type ReportParameter =
  | {
      type: 'expression';
      name: string;
      label: string;
      expression: string;
    }
  | {
      type: 'literal-date';
      name: string;
      label: string;
      value: string;
    }
  | {
      type: 'literal-number';
      name: string;
      label: string;
      value: number;
    }
  | {
      type: 'select-date';
      name: string;
      label: string;
      values: {
        value: string;
        label: string;
      }[];
    }
  | {
      type: 'select-number';
      name: string;
      label: string;
      values: {
        value: number;
        label: string;
      }[];
    };

export const reportParameterTypes: ReportParameter['type'][] = [
  'expression',
  'literal-date',
  'literal-number',
  'select-date',
  'select-number'
];

export const reportParameterTypeNames: Record<ReportParameter['type'], string> =
  {
    expression: 'Formula',
    'literal-date': 'Date',
    'literal-number': 'Number',
    'select-date': 'Date pick list',
    'select-number': 'Number pick list'
  };

export interface ReportDefinition {
  parameters: ReportParameter[];
  filters: ReportFilter[];
  columns: ReportColumn[];
}

export interface Report extends SharedResource {
  report_id: string;
  entity_type: ReportEntityType;
  layout: ReportLayout;
  project_id: string | null;
  title: string;
  description: string;
  is_shared: ReportSharedWith;
  definition: ReportDefinition;
}

export interface ReportListItem {
  report_id: string;
  project_id: string | null;
  title: string;
  description: string;
  is_shared: ReportSharedWith;
  entity_type: ReportEntityType;
  layout: ReportLayout;
  is_subscribed: boolean;
  subscriber_count: number;
  total_view_count_30_days: number;
  user_view_count_30_days: number;
  user_last_viewed: string;
  project_name: string | null;
  project_login: string | null;

  create_datetime: string;
  create_user_id: string;
  create_user_login: string;
  create_user_name: string;
  create_user_avatar_id: string | null;
  create_user_is_active: boolean;
  create_user_is_ooo: boolean;
  create_organization_id: string;
  create_organization_login: string;
  create_organization_name: string;
  create_organization_avatar_id: string | null;
}

export interface PostReportArgs {
  entity_type: ReportEntityType;
  project_id: string | null;
  title: string;
  description: string;
  is_shared: ReportSharedWith;
  definition: ReportDefinition;
  parameter_values?: Record<string, string | number>;
  page?: number;
}

export interface ReportDefaults {
  columns: ReportColumn[];
  filters: ReportFilter[];
  sorts: ReportSort[];
}

export interface ReportSubscription extends OrganizationSharedResource {
  report_subscription_id: string;
  report_id: string;
  user_id: string;
  days: number;
  time: number;
  iana_timezone: string;
  send_zero: boolean;
}

export const enum ReportSubscriptionJobState {
  Scheduled = 0,
  Processing = 1,
  Complete = 2,
  Exception = 3,
  Expired = 4
}

export interface UpdateReportSubscriptionArgs {
  days: number;
  time: number;
  send_zero: boolean;
}

export const reportDefaults: Record<ReportEntityType, ReportDefinition> = {
  activity: {
    parameters: [],
    columns: [
      {
        type: 'standard',
        property_id: wellKnownProperties.activity.activity_definition_id,
        grouping: { type: 'none' },
        sort: null
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.activity.status,
        grouping: { type: 'none' },
        sort: null
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.activity.assignee_organization_id,
        grouping: { type: 'none' },
        sort: null
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.activity.assignee_user_id,
        grouping: { type: 'none' },
        sort: null
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.activity.start_date,
        grouping: { type: 'none' },
        sort: '0/desc'
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.activity.end_date,
        grouping: { type: 'none' },
        sort: null
      }
    ],
    filters: [
      {
        type: 'standard',
        property_id: wellKnownProperties.activity.status,
        operator: 'in',
        value: openActivityStatuses.map(id => ({
          id,
          title: activityStatusTitles[id][defaultLocale]
        }))
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.activity.assignee_user_id,
        operator: 'in',
        value: [reportWildcards.me]
      }
    ]
  },
  person: {
    parameters: [],
    columns: [
      {
        type: 'standard',
        property_id: wellKnownProperties.person.full_name,
        grouping: { type: 'none' },
        sort: '0/asc'
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.person.dob,
        grouping: { type: 'none' },
        sort: null
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.person.gender,
        grouping: { type: 'none' },
        sort: null
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.person.primary_physical.address,
        grouping: { type: 'none' },
        sort: null
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.person.create_user_id,
        grouping: { type: 'none' },
        sort: null
      }
    ],
    filters: [
      {
        type: 'standard',
        property_id: wellKnownProperties.person.create_organization_id,
        operator: 'in',
        value: [reportWildcards.myOrganization]
      }
    ]
  },
  user_organization: {
    parameters: [],
    columns: [
      {
        type: 'standard',
        property_id: wellKnownProperties.user_organization.login,
        grouping: { type: 'none' },
        sort: null
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.user_organization.family_name,
        grouping: { type: 'none' },
        sort: '0/asc'
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.user_organization.given_name,
        grouping: { type: 'none' },
        sort: '1/asc'
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.user_organization.email,
        grouping: { type: 'none' },
        sort: null
      },
      {
        type: 'standard',
        property_id:
          wellKnownProperties.user_organization.primary_phone.formatted,
        grouping: { type: 'none' },
        sort: null
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.user_organization.projects,
        grouping: { type: 'none' },
        sort: null
      }
    ],
    filters: [
      {
        type: 'standard',
        property_id: wellKnownProperties.user_organization.organization_id,
        operator: 'in',
        value: [reportWildcards.myOrganization]
      }
    ]
  },
  organization_project: {
    parameters: [],
    columns: [
      {
        type: 'standard',
        property_id: wellKnownProperties.organization_project.organization_id,
        grouping: { type: 'none' },
        sort: '0/asc'
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.organization_project.project_id,
        grouping: { type: 'none' },
        sort: null
      },
      {
        type: 'standard',
        property_id: wellKnownProperties.organization_project.project_join_date,
        grouping: { type: 'none' },
        sort: null
      },
      {
        type: 'standard',
        property_id:
          wellKnownProperties.organization_project.primary_physical.region_abbr,
        grouping: { type: 'none' },
        sort: null
      }
    ],
    filters: [
      {
        type: 'standard',
        property_id: wellKnownProperties.organization_project.is_active,
        operator: '=',
        value: true
      }
    ]
  }
};

export function getReportReferencedPropertyIds(
  definition: ReportDefinition
): number[] {
  const ids = new Set<number>();
  for (const item of definition.filters) {
    switch (item.type) {
      case 'standard':
        ids.add(item.property_id);
        break;
      case 'subquery':
        if (item.aggregate !== 'count') {
          ids.add(item.subquery_property_id);
        }
        item.filters?.forEach(filter => ids.add(filter.property_id));
        break;
      default:
        throw new Error(`Unexpected column type "${(item as any).type}".`);
    }
  }
  for (const item of definition.columns) {
    switch (item.type) {
      case 'standard':
        ids.add(item.property_id);
        break;
      case 'subquery':
        if (item.aggregate !== 'count') {
          ids.add(item.subquery_property_id);
        }
        item.filters?.forEach(filter => ids.add(filter.property_id));
        break;
      case 'expression':
        break;
      default:
        throw new Error(`Unexpected column type "${(item as any).type}".`);
    }
  }
  return Array.from(ids).sort();
}

export function getPropertyLabelForReport(
  entity: ReportEntityType,
  property: Property
) {
  let label = property.label;
  if (property.response || property.entity_type === entity) {
    return label;
  }
  if (!/^[A-Z][A-Z]/.test(label)) {
    label = label.toLowerCase();
  }
  const entitySingular =
    reportEntityInfo[property.entity_type as ReportEntityType].singular;
  return `${entitySingular} ${label}`;
}

export function getReportFilterOperators(
  filter:
    | Pick<StandardReportFilter, 'type'>
    | Pick<SubqueryReportFilter, 'type' | 'aggregate'>,
  property: Pick<Property, 'property_id' | 'type' | 'choice_type'> | null
): ReportFilterOperator[] {
  const aggregate = filter.type === 'standard' ? 'max' : filter.aggregate;
  const resultType = aggregateFunctionResultType[aggregate];
  switch (resultType) {
    case 'decimal':
    case 'int':
    case 'inherit-number':
      return [...comparisonOperators];
    case 'inherit': {
      if (!property) {
        return [];
      }
      let ops: ReportFilterOperator[];
      switch (property.type) {
        case 'signature':
        case 'medications':
        case 'attachment':
          ops = [];
          break;
        case 'currency':
        case 'date':
        case 'decimal':
        case 'int':
          if (property.choice_type === 'none') {
            ops = ['=', '<', '<=', '>', '>='];
          } else {
            ops = ['in', 'not in'];
          }
          break;
        case 'varchar100':
          if (property.choice_type === 'none') {
            ops = [
              '=',
              '<',
              '<=',
              '>',
              '>=',
              'startswith',
              'notstartswith',
              'contains',
              'notcontains'
            ];
          } else {
            ops = ['in', 'not in'];
          }
          break;
        case 'varcharmax':
          ops = ['=', 'startswith', 'notstartswith', 'contains', 'notcontains'];
          break;
        case 'uniqueidentifier':
          if (property.choice_type === 'none') {
            throw new Error(
              `Expected uniqueidentifier property to have choice_type ("${property.property_id}").`
            );
          }
          ops = ['in', 'not in'];
          break;
        case 'bit':
          ops = ['='];
          break;
        case 'activity':
          ops = ['in', 'not in'];
          break;
        default:
          throw new Error(`Unsupported property type "${property.type}".`);
      }
      if (getBlankType(property)) {
        ops.push('blank', 'notblank');
      }
      return ops;
    }
    default:
      throw new Error(
        `Unexpected aggregate function result type "${resultType}".`
      );
  }
}

export type ReportFilterValueTypeInfo =
  | { type: PropertyType; choice_type: 'none' }
  | Pick<Property, 'property_id' | 'type' | 'choice_type'>;

export function getReportFilterValueType(
  filter: ReportFilter,
  property: Pick<Property, 'property_id' | 'type' | 'choice_type'> | null,
  operator: ReportFilterOperator
): ReportFilterValueTypeInfo | 'blank' | null {
  const aggregate = filter.type === 'standard' ? 'max' : filter.aggregate;
  const resultType = aggregateFunctionResultType[aggregate];
  switch (resultType) {
    case 'decimal':
    case 'int':
      return { type: resultType, choice_type: 'none' };
    case 'inherit-number':
    case 'inherit': {
      if (!property) {
        return null;
      }
      switch (property.type) {
        case 'medications':
          return null;
        case 'signature':
        case 'attachment':
          if (operator === 'blank' || operator === 'notblank') {
            return 'blank';
          }
          return null;
        case 'bit':
        case 'currency':
        case 'date':
        case 'decimal':
        case 'int':
        case 'varchar100':
        case 'varcharmax':
        case 'uniqueidentifier':
        case 'activity':
          if (operator === 'blank' || operator === 'notblank') {
            return 'blank';
          }
          return property;
        default:
          throw new Error(`Unsupported property type "${property.type}".`);
      }
    }
    default:
      throw new Error(
        `Unexpected aggregate function result type "${resultType}".`
      );
  }
}

export const reportGridColumnDefTypes = {
  single_choice: 'single_choice',
  multiple_choice: 'multiple_choice',
  boolean: 'boolean',
  date: 'date',
  decimal: 'decimal',
  currency: 'currency',
  int: 'int',
  row_href: 'row_href',
  composite_address: 'composite_address',
  composite_email: 'composite_email',
  composite_phone: 'composite_phone',
  composite_full_name: 'composite_full_name',
  value_map: 'value_map'
};
