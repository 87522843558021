import { newElementId } from '@cumu/shared';
import { attr as cattr, target } from '@github/catalyst';
import { getPointerSiblingElements } from '../forms/utilities';
import { clearValidationErrors } from '../forms/validation';
import { controller } from './controller';
import { dispatchChange, focusSomething, scrollIntoViewIfNeeded } from './util';

@controller('editable-list')
class EditableListElement extends HTMLElement {
  @target itemTemplate: HTMLTemplateElement | undefined;
  @target secondaryItemTemplate: HTMLTemplateElement | undefined;
  @target tertiaryItemTemplate: HTMLTemplateElement | undefined;
  @target blankslate: HTMLElement | undefined;
  @target blankslateTemplate: HTMLTemplateElement | undefined;
  @cattr declare rowHighlightClass: string;

  connectedCallback() {
    this.addEventListener('paste', this.handlePaste);
  }

  disconnectedCallback() {
    this.removeEventListener('paste', this.handlePaste);
  }

  safariBug(list: HTMLElement) {
    // https://support.cumulus.care/a/tickets/1992
    list.style.zoom = '1';
    setTimeout(() => (list.style.zoom = ''));
  }

  handlePaste = (event: ClipboardEvent) => {
    let li =
      event.target instanceof HTMLInputElement && event.target.closest('li');
    if (!li) {
      return;
    }
    if (li.closest('editable-list') !== this) {
      return;
    }
    const rows = (event.clipboardData?.getData('text/plain') ?? '')
      .split(/\r?\n/)
      .map(row => row.split('\t').map(cell => cell.trim()));
    if (rows.length < 2) {
      return;
    }
    event.preventDefault();
    let startInputIndex = NaN;
    let focusTarget: HTMLInputElement | undefined;
    for (const row of rows) {
      if (!li) {
        li = this.addListItem()!;
      }
      let col = 0;
      let inputIndex = 0;
      for (const input of li.querySelectorAll('input')) {
        if (
          row.length > col &&
          input.form &&
          ['text', 'email', 'number'].includes(input.type) &&
          getComputedStyle(input).display !== 'none' &&
          (inputIndex >= startInputIndex || input === event.target)
        ) {
          if (input === event.target) {
            startInputIndex = inputIndex;
          }
          input.value = row[col];
          dispatchChange(input);
          focusTarget = input;
          col++;
        }
        inputIndex++;
      }
      li = li.nextElementSibling as HTMLLIElement;
    }
    focusTarget?.focus();
    focusTarget && scrollIntoViewIfNeeded(focusTarget);
  };

  removeListItem(event: Event) {
    const target = event.target as HTMLElement;
    const item = target.closest('li')!;
    item
      .querySelectorAll<HTMLInputElement>(
        '.form-group.errored input:not([type="hidden"])'
      )
      .forEach(input => {
        if (input.form) {
          clearValidationErrors(input);
        }
      });
    const list = item.parentElement!;
    const index = Array.from(list.children).indexOf(item);
    let next = item.nextElementSibling;
    focusSomething(item.previousElementSibling) || focusSomething(this);
    item.remove();
    this.synchronizeBlankslate();
    const listName = this.getAttribute('name')!;
    let i = index;
    while (next) {
      updatePointers(next, listName, i);
      next = next.nextElementSibling;
      i++;
    }
    this.dispatchEvent(
      new CustomEvent('editable-list-change', {
        bubbles: true,
        detail: {
          indexes: [...Array(list.children.length + 1).keys()].reduce(
            (p, c) => {
              p[c] = c === index ? null : c < index ? c : c - 1;
              return p;
            },
            {} as Record<number, number | null>
          )
        }
      })
    );
    this.safariBug(list);
  }

  moveListItemUp(event: Event) {
    const target = event.target as HTMLElement;
    const item = target.closest('li')!;
    const list = item.parentElement!;
    const index = Array.from(list.children).indexOf(item);
    const previous = item.previousElementSibling;
    const listName = this.getAttribute('name')!;
    if (previous) {
      this.rowHighlightClass && item.classList.remove(this.rowHighlightClass);
      item.remove();
      previous.before(item);
      updatePointers(item, listName, index - 1);
      updatePointers(previous, listName, index);
      target.focus();
      this.dispatchEvent(
        new CustomEvent('editable-list-change', {
          bubbles: true,
          detail: {
            indexes: [...Array(list.children.length).keys()].reduce(
              (p, c) => {
                p[c] = c === index - 1 ? index : c === index ? index - 1 : c;
                return p;
              },
              {} as Record<number, number | null>
            )
          }
        })
      );
      this.rowHighlightClass && item.classList.add(this.rowHighlightClass);
    }
  }

  moveListItemDown(event: Event) {
    const target = event.target as HTMLElement;
    const item = target.closest('li')!;
    const list = item.parentElement!;
    const index = Array.from(list.children).indexOf(item);
    const next = item.nextElementSibling;
    const listName = this.getAttribute('name')!;
    if (next) {
      this.rowHighlightClass && item.classList.remove(this.rowHighlightClass);
      item.remove();
      next.after(item);
      updatePointers(item, listName, index + 1);
      updatePointers(next, listName, index);
      target.focus();
      this.dispatchEvent(
        new CustomEvent('editable-list-change', {
          bubbles: true,
          detail: {
            indexes: [...Array(list.children.length).keys()].reduce(
              (p, c) => {
                p[c] = c === index + 1 ? index : c === index ? index + 1 : c;
                return p;
              },
              {} as Record<number, number | null>
            )
          }
        })
      );
      this.rowHighlightClass && item.classList.add(this.rowHighlightClass);
    }
  }

  moveListItemTop(event: Event) {
    const target = event.target as HTMLElement;
    const item = target.closest('li')!;
    const list = item.parentElement!;
    const index = Array.from(list.children).indexOf(item);
    const first = list.firstElementChild;
    const listName = this.getAttribute('name')!;
    if (first && first !== item) {
      this.rowHighlightClass && item.classList.remove(this.rowHighlightClass);
      item.remove();
      first.before(item);
      for (let i = 0; i <= index; i++) {
        updatePointers(list.children.item(i)!, listName, i);
      }
      target.focus();
      this.dispatchEvent(
        new CustomEvent('editable-list-change', {
          bubbles: true,
          detail: {
            indexes: [...Array(list.children.length).keys()].reduce(
              (p, c) => {
                p[c] = c === index ? 0 : c < index ? c - 1 : c;
                return p;
              },
              {} as Record<number, number | null>
            )
          }
        })
      );
      this.rowHighlightClass && item.classList.add(this.rowHighlightClass);
    }
  }

  moveListItemBottom(event: Event) {
    const target = event.target as HTMLElement;
    const item = target.closest('li')!;
    const list = item.parentElement!;
    const index = Array.from(list.children).indexOf(item);
    const last = list.lastElementChild;
    const listName = this.getAttribute('name')!;
    if (last && last !== item) {
      this.rowHighlightClass && item.classList.remove(this.rowHighlightClass);
      item.remove();
      last.after(item);
      for (let i = index; i < list.children.length; i++) {
        updatePointers(list.children.item(i)!, listName, i);
      }
      target.focus();
      this.dispatchEvent(
        new CustomEvent('editable-list-change', {
          bubbles: true,
          detail: {
            indexes: [...Array(list.children.length).keys()].reduce(
              (p, c) => {
                p[c] =
                  c < index
                    ? c
                    : c === index
                      ? list.children.length - 1
                      : c - 1;
                return p;
              },
              {} as Record<number, number | null>
            )
          }
        })
      );
      this.rowHighlightClass && item.classList.add(this.rowHighlightClass);
    }
  }

  editListItem(event: Event) {
    const target = event.target as HTMLElement;
    const item = target.closest('li')!;
    item.classList.add('Editable--editing');
    focusSomething(item);
  }

  duplicateListItem(event: Event) {
    const target = event.target as HTMLElement;
    const item = target.closest('li')!;
    this.rowHighlightClass && item.classList.remove(this.rowHighlightClass);
    const template = document.createElement('template');
    const clone = item.cloneNode(true) as HTMLLIElement;
    const itemSelects = item.querySelectorAll('select');
    const cloneSelects = clone.querySelectorAll('select');
    for (let i = 0; i < itemSelects.length; i++) {
      cloneSelects[i].querySelector('[selected]')?.removeAttribute('selected');
      cloneSelects[i]
        .querySelector(
          `[value="${itemSelects[i].hasAttribute('data-clone-value') ? itemSelects[i].getAttribute('data-clone-value') : itemSelects[i].value}"]`
        )
        ?.toggleAttribute('selected', true);
    }
    template.content.append(clone);
    return this.insertListItemInternal(template, item);
  }

  addListItem() {
    return this.insertListItemInternal(this.itemTemplate);
  }

  addSecondaryListItem() {
    return this.insertListItemInternal(this.secondaryItemTemplate);
  }

  addTertiaryListItem() {
    return this.insertListItemInternal(this.tertiaryItemTemplate);
  }

  insertListItemInternal(
    itemTemplate: HTMLTemplateElement | undefined,
    after?: HTMLLIElement
  ) {
    const list = this.querySelector<HTMLOListElement | HTMLUListElement>(
      'ol,ul'
    );
    if (!list || !itemTemplate) {
      return null;
    }
    const maxItems = parseInt(this.getAttribute('max-items') ?? '');
    if (Number.isInteger(maxItems) && list.children.length >= maxItems) {
      const label =
        document.getElementById(
          list.getAttribute('aria-labelledby') ?? 'undefined-labelledby-id'
        )?.innerText ??
        list.getAttribute('aria-label') ??
        'items';
      alert(`The maximum number of ${label.toLowerCase()} is ${maxItems}.`);
      return;
    }

    const fragment = instantiateTemplate(itemTemplate);
    let index: number;
    if (after) {
      after.after(fragment);
      index = Array.from(list.children).indexOf(after) + 1;
    } else {
      list.append(fragment);
      index = list.children.length - 1;
    }
    this.synchronizeBlankslate();
    const inserted = list.children.item(index) as HTMLLIElement;
    focusSomething(inserted);
    while (index < list.children.length) {
      synchronizePointers(list.children.item(index)!);
      index++;
    }

    inserted
      .querySelectorAll<HTMLInputElement>(
        'input[type="checkbox"][data-action*="change:editable-list#handlePrimaryChanged"]'
      )
      .forEach(input => {
        input.checked = !getPointerSiblingElements(input)
          .filter(input =>
            input.matches(
              '[data-action*="change:editable-list#handlePrimaryChanged"]'
            )
          )
          .some(el => (el as HTMLInputElement).checked);
      });

    this.dispatchEvent(
      new CustomEvent('editable-list-change', {
        bubbles: true,
        detail: {
          indexes: Array.from({
            length: list.children.length
          })
            .map((_, index) => index)
            .reduce(
              (p, c) => {
                p[c] = c;
                return p;
              },
              {} as Record<number, number | null>
            )
        }
      })
    );
    this.safariBug(list);
    this.rowHighlightClass && inserted.classList.add(this.rowHighlightClass);
    return inserted;
  }

  synchronizeBlankslate() {
    const list = this.querySelector<HTMLOListElement | HTMLUListElement>(
      'ol,ul'
    );
    if (!list || !this.blankslateTemplate) {
      return;
    }
    if (list.children.length > 1 && this.blankslate) {
      this.blankslate.remove();
    } else if (list.children.length === 0) {
      const fragment = instantiateTemplate(this.blankslateTemplate);
      list.append(fragment);
    }
  }

  handlePrimaryChanged({ target }: { target: HTMLInputElement }) {
    if (target.checked) {
      getPointerSiblingElements(target)
        .filter(el =>
          el.matches(
            '[data-action*="change:editable-list#handlePrimaryChanged"]'
          )
        )
        .forEach(el => {
          (el as HTMLInputElement).checked = el === target;
        });
    }
  }
}

const referenceAttributes = [
  'for',
  'aria-labelledby',
  'aria-describedby',
  'aria-owns',
  'data-toggle-for',
  'form',
  'list',
  'input',
  'error-alert'
];

export function instantiateTemplate(
  template: HTMLTemplateElement
): DocumentFragment {
  const fragment = template.content.cloneNode(true) as DocumentFragment;
  for (const el of Array.from(fragment.querySelectorAll('[id]'))) {
    const newId = newElementId();
    for (const attr of referenceAttributes) {
      for (const ref of fragment.querySelectorAll(`[${attr}~="${el.id}"]`)) {
        const current = ref.getAttribute(attr)!;
        const updated = current
          .split(/\s+/)
          .map(v => (v === el.id ? newId : v))
          .join(' ');
        // console.log(`${attr} "${current}" "${updated}"`);
        ref.setAttribute(attr, updated);
      }
    }
    el.id = newId;
  }
  return fragment;
}

export function synchronizePointers(el: Element) {
  let li = el.closest('li') ?? null;
  let editableList = li?.closest('editable-list') ?? null;
  const updates: { listName: string; index: number }[] = [];
  while (editableList && li) {
    const listName = editableList.getAttribute('name')!;
    const index = Array.prototype.indexOf.call(li.parentElement!.children, li);
    updates.unshift({ listName, index });
    li = editableList.closest('li');
    editableList = li?.closest('editable-list') ?? null;
  }
  for (const { listName, index } of updates) {
    updatePointers(el, listName, index);
  }
}

function updatePointer(el: Element, listName: string, index: number) {
  const suffix = el
    .getAttribute('name')!
    .replace(listName, '')
    .replace(/^\/\d+/, '');
  el.setAttribute('name', `${listName}/${index}${suffix}`);
}

function updatePointers(el: Element, listName: string, index: number) {
  const selector = `[name^="${listName}/"]`;
  if (el.matches(selector)) {
    updatePointer(el, listName, index);
  }
  el.querySelectorAll(selector).forEach(el =>
    updatePointer(el, listName, index)
  );
}

declare global {
  interface Window {
    EditableListElement: typeof EditableListElement;
  }
  interface HTMLElementTagNameMap {
    'editable-list': EditableListElement;
  }
}
