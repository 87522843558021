import { Address } from './address';
import { Contact } from './contact';
import { EntityWithContactInfo } from './contact-info';
import { Email } from './email';
import { Phone, PhoneType } from './phone';
import { SharedResource } from './resource';

export const enum PersonOrganizationAssociationType {
  Creator = 1,
  Activity = 2,
  Consent = 4
}

export interface Person extends SharedResource, EntityWithContactInfo {
  person_id: string;
  last_name: string;
  first_name: string;
  middle_name: string;
  dob: string | null;
  ssn: string | null;
  veteran_id: string | null;
  medicaid_id: string | null;
  agingis_id: string | null;
  external_id: string | null;
  gender: number | null;
  gender_identity: number | null;
  sexual_orientation: number | null;
  race: number | null;
  ethnicity: number | null;
  marital_status: number | null;
  primary_language: number | null;
  user_id: string | null;
  emails?: Email[];
  phones?: Phone[];
  addresses?: Address[];
  contacts?: Contact[];
}

export interface PersonSearchResult {
  person_id: string;
  last_name: string;
  first_name: string;
  middle_name: string;
  dob: string | null;
  ssn: string | null;
  veteran_id: string | null;
  medicaid_id: string | null;
  agingis_id: string | null;
  external_id: string | null;
  gender: number | null;
  primary_physical_street_address: string | null;
  primary_physical_sub_address: string | null;
  primary_physical_city: string | null;
  primary_physical_region_abbr: string | null;
  primary_physical_postal_code: string | null;
  primary_physical_postal_code_ext: string | null;
  primary_physical_subregion: string | null;
  primary_phone_type: PhoneType | null;
  primary_phone_number: string | null;
  primary_phone_extension: string | null;
  date?: string;
  most_recent_activity?: {
    activity_id: string;
    status: string;
    start_date: string;
    end_date: string;
    total_count: number;
  };
}

export interface RucaLookupItem {
  start_date: string;
  primary_code: number | null;
}

export interface AreaOfPersistentUnemploymentLookupItem {
  start_date: string;
  persistent_unemployment: boolean;
}

export const fhirGenderToPersonGender: Record<string, number> = {
  male: 0,
  female: 1,
  other: 2,
  unknown: -1
};

export const primaryLanguageOptions = [
  { choice_id: 0, locale: 'en', name: 'English' },
  { choice_id: 45, locale: 'sq', name: 'Albanian' },
  { choice_id: 1, locale: 'ar', name: 'Arabic' },
  { choice_id: 2, locale: 'bn', name: 'Bengali' },
  { choice_id: 3, locale: 'bs', name: 'Bosnian' },
  { choice_id: 4, locale: 'bg', name: 'Bulgarian' },
  { choice_id: 5, locale: 'zh', name: 'Chinese' },
  { choice_id: 6, locale: 'hr', name: 'Croatian' },
  { choice_id: 7, locale: 'cs', name: 'Czech' },
  { choice_id: 8, locale: 'da', name: 'Danish' },
  { choice_id: 9, locale: 'nl', name: 'Dutch' },
  { choice_id: 10, locale: 'et', name: 'Estonian' },
  { choice_id: 46, locale: 'fa', name: 'Farsi' },
  { choice_id: 11, locale: 'fil', name: 'Filipino' },
  { choice_id: 12, locale: 'fi', name: 'Finnish' },
  { choice_id: 13, locale: 'fr', name: 'French' },
  { choice_id: 14, locale: 'fy', name: 'Frisian' },
  { choice_id: 15, locale: 'de', name: 'German' },
  { choice_id: 16, locale: 'el', name: 'Greek' },
  { choice_id: 17, locale: 'ht', name: 'Haitian Creole' },
  { choice_id: 18, locale: 'hi', name: 'Hindi' },
  { choice_id: 19, locale: 'mww', name: 'Hmong Daw' },
  { choice_id: 20, locale: 'is', name: 'Icelandic' },
  { choice_id: 21, locale: 'it', name: 'Italian' },
  { choice_id: 22, locale: 'ja', name: 'Japanese' },
  { choice_id: 47, locale: 'rw', name: 'Kinyarwanda' },
  { choice_id: 23, locale: 'ko', name: 'Korean' },
  { choice_id: 24, locale: 'lv', name: 'Latvian' },
  { choice_id: 48, locale: 'lir', name: 'Liberian Kreyol' },
  { choice_id: 49, locale: 'ln', name: 'Lingala' },
  { choice_id: 25, locale: 'lt', name: 'Lithuanian' },
  { choice_id: 26, locale: 'no', name: 'Norwegian' },
  { choice_id: 27, locale: 'ps', name: 'Pashto' },
  { choice_id: 28, locale: 'fa', name: 'Persian' },
  { choice_id: 29, locale: 'pl', name: 'Polish' },
  { choice_id: 30, locale: 'pt', name: 'Portuguese' },
  { choice_id: 31, locale: 'pa', name: 'Punjabi' },
  { choice_id: 32, locale: 'ro', name: 'Romanian' },
  { choice_id: 33, locale: 'ru', name: 'Russian' },
  { choice_id: 34, locale: 'sm', name: 'Samoan' },
  { choice_id: 35, locale: 'sr', name: 'Serbian' },
  { choice_id: 36, locale: 'sk', name: 'Slovakian' },
  { choice_id: 37, locale: 'sl', name: 'Slovenian' },
  { choice_id: 38, locale: 'so', name: 'Somali' },
  { choice_id: 39, locale: 'es', name: 'Spanish' },
  { choice_id: 50, locale: 'sw', name: 'Swahili' },
  { choice_id: 40, locale: 'sv', name: 'Swedish' },
  { choice_id: 41, locale: 'te', name: 'Telugu' },
  { choice_id: 42, locale: 'th', name: 'Thai' },
  { choice_id: 51, locale: 'tr', name: 'Turkish' },
  { choice_id: 52, locale: 'uk', name: 'Ukrainian' },
  { choice_id: 43, locale: 'ur', name: 'Urdu' },
  { choice_id: 44, locale: 'vi', name: 'Vietnamese' }
];

export const genderOptions = [
  { label: 'Female', choice_id: 1, fhirCode: 'female' },
  { label: 'Male', choice_id: 0, fhirCode: 'male' },
  { label: 'Other', choice_id: 2, fhirCode: 'oth' },
  { label: 'Prefer not to answer', choice_id: 31, fhirCode: 'asked-declined' }
];

// https://hl7.org/fhir/us/core/STU7/ValueSet-omb-ethnicity-category.html
export const ethnicityOptions = [
  { label: 'Hispanic or Latino', choice_id: 1, fhirCode: '2135-2' },
  { label: 'Not Hispanic or Latino', choice_id: 0, fhirCode: '2186-5' },
  { label: 'Prefer not to answer', choice_id: 31, fhirCode: 'ASKU' }
];

// https://hl7.org/fhir/us/core/STU7/ValueSet-omb-race-category.html
export const raceOptions = [
  {
    label: 'American Indian or Alaska Native',
    choice_id: 0,
    exclusive: false,
    fhirCode: '1002-5'
  },
  { label: 'Asian', choice_id: 1, exclusive: false, fhirCode: '2028-9' },
  {
    label: 'Black or African American',
    choice_id: 2,
    exclusive: false,
    fhirCode: '2054-5'
  },
  {
    label: 'Native Hawaiian or Other Pacific Islander',
    choice_id: 3,
    exclusive: false,
    fhirCode: '2076-8'
  },
  { label: 'White', choice_id: 4, exclusive: false, fhirCode: '2106-3' },
  {
    label: 'Prefer not to answer',
    choice_id: 31,
    exclusive: true,
    fhirCode: 'ASKU'
  }
];

export const sexualOrientationOptions = [
  { label: 'Heterosexual', choice_id: 0 },
  { label: 'Homosexual', choice_id: 1 },
  { label: 'Bisexual', choice_id: 2 },
  { label: 'Other', choice_id: 3 },
  { label: 'Prefer not to answer', choice_id: 31 }
];

export const maritalStatusOptions = [
  { label: 'Married', choice_id: 0, fhirCode: 'M' },
  { label: 'Never married', choice_id: 1, fhirCode: 'S' },
  { label: 'Separated', choice_id: 2, fhirCode: 'L' },
  { label: 'Divorced', choice_id: 3, fhirCode: 'D' },
  { label: 'Widowed', choice_id: 4, fhirCode: 'W' },
  { label: 'Prefer not to answer', choice_id: 31, fhirCode: 'UNK' }
];

export const genderIdentityOptions = [
  { label: 'Female', choice_id: 1 },
  { label: 'Male', choice_id: 0 },
  { label: 'Other', choice_id: 2 },
  { label: 'Non-binary', choice_id: 3 },
  { label: 'Transgender female', choice_id: 5 },
  { label: 'Transgender male', choice_id: 4 },
  { label: "Don't know", choice_id: 6 },
  { label: 'Prefer not to answer', choice_id: 31 }
];
