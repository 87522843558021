import {
  Locale,
  loc_agency,
  loc_cancelled,
  loc_cancelledStatusDesc,
  loc_completed,
  loc_completedStatusDesc,
  loc_draft,
  loc_draftStatusDesc,
  loc_inProgress,
  loc_inProgressStatusDesc,
  loc_individualConsent,
  loc_individualConsentDesc,
  loc_individualEncounter,
  loc_individualEncounterDesc,
  loc_individualEpisode,
  loc_individualEpisodeDesc,
  loc_individualQuestionnaire,
  loc_individualQuestionnaireDesc,
  loc_individualReferral,
  loc_individualReferralDesc,
  loc_individualTask,
  loc_individualTaskDesc,
  loc_notificationReasonAssignee,
  loc_notificationReasonAutomatic,
  loc_notificationReasonCommentor,
  loc_notificationReasonCreator,
  loc_notificationReasonManual,
  loc_notificationReasonMentioned,
  loc_notificationReasonNone,
  loc_notificationReasonTeamMentioned,
  loc_notificationReasonUnsubscribed,
  loc_notificationReasonUpdator,
  loc_onHold,
  loc_onHoldStatusDesc,
  loc_organizationAgencyDesc,
  loc_organizationEvent,
  loc_organizationEventDesc,
  loc_organizationPeriodDesc,
  loc_organizationQuestionnaire,
  loc_organizationQuestionnaireDesc,
  loc_organizationSiteDesc,
  loc_organizationTask,
  loc_organizationTaskDesc,
  loc_period,
  loc_ready,
  loc_readyStatusDesc,
  loc_site
} from '@cumu/strings';
import {
  ActivityExpressionScope,
  evaluateActivityExpression
} from '../expressions/activities';
import {
  clockIcon,
  issueClosedIcon,
  issueDraftIcon,
  issueOpenedIcon,
  issueReopenedIcon,
  skipIcon
} from '../icons';
import { uuidToBase58 } from '../new-id';
import { Address } from './address';
import { Contact } from './contact';
import { Email } from './email';
import { Expression } from './expression';
import { LookupItem } from './lookup-item';
import { OrganizationInfo } from './organization';
import { Phone } from './phone';
import { ReportFilterComparisonOperator, StandardReportFilter } from './report';
import { ProjectResource, SharedResource } from './resource';
import { EntityWithStatus, PrimerStatus, Status } from './status';

export const activityCommentMaxLength = 5000;
export const maxActivityDefinitionInstructionsLength = 1000;
export const maxActivityDefinitionDueDateDays = 365 * 4;

export function getHeadingElementId(text: string) {
  return `section-` + text.toLowerCase().replace(/[^a-z0-9]+/g, '-');
}

export type ActivityType =
  | 'individual-referral'
  | 'individual-task'
  | 'individual-questionnaire'
  | 'individual-episode'
  | 'individual-encounter'
  | 'individual-consent'
  | 'organization-agency'
  | 'organization-site'
  | 'organization-task'
  | 'organization-questionnaire'
  | 'organization-event'
  | 'organization-period';

export const activityTypes: ActivityType[] = [
  'individual-referral',
  'individual-task',
  'individual-questionnaire',
  'individual-episode',
  'individual-encounter',
  'individual-consent',
  'organization-agency',
  'organization-site',
  'organization-task',
  'organization-questionnaire',
  'organization-event',
  'organization-period'
];

const individualActivityTypes = new Set<ActivityType>(
  activityTypes.filter(x => x.startsWith('individual-'))
);

export function isIndividualActivityType(
  type: ActivityType | null | undefined
): type is
  | 'individual-referral'
  | 'individual-task'
  | 'individual-questionnaire'
  | 'individual-episode'
  | 'individual-encounter'
  | 'individual-consent' {
  return !!type && individualActivityTypes.has(type);
}

const endDateAlwaysRequiredActivityTypes = new Set<ActivityType>([
  'organization-event',
  'individual-consent',
  'organization-period'
]);

export function isEndDateAlwaysRequiredActivityType(
  type: ActivityType | null | undefined
): type is 'organization-event' | 'individual-consent' | 'organization-period' {
  return !!type && endDateAlwaysRequiredActivityTypes.has(type);
}

export function activityTypeHasTitle(type: ActivityType) {
  return [
    'organization-event',
    'organization-agency',
    'organization-site',
    'organization-period'
  ].includes(type);
}

export function isActivityEndDateRequired(
  type: ActivityType,
  status: string
): boolean {
  return (
    status === 'completed' ||
    status === 'cancelled' ||
    isEndDateAlwaysRequiredActivityType(type)
  );
}

const dueDateActivityTypes = new Set<ActivityType>([
  'individual-referral',
  'individual-task',
  'individual-questionnaire',
  'organization-task',
  'organization-questionnaire'
]);

export function isDueDateActivityType(
  type: ActivityType | null | undefined
): type is
  | 'individual-referral'
  | 'individual-task'
  | 'individual-questionnaire'
  | 'organization-task'
  | 'organization-questionnaire' {
  return !!type && dueDateActivityTypes.has(type);
}

export function activityTypeToFHIRResourceType(type: ActivityType) {
  switch (type) {
    case 'individual-questionnaire':
      return 'QuestionnaireResponse';
    case 'individual-referral':
      return 'ServiceRequest';
    case 'individual-task':
      return 'Task';
    case 'organization-event':
      return 'Task';
    case 'organization-agency':
      return 'Organization';
    case 'organization-questionnaire':
      return 'QuestionnaireResponse';
    case 'organization-task':
      return 'Task';
    default:
      throw new Error(`Unexpected activity type: ${type}`);
  }
}

export const enum ActivityDefinitionFlags {
  Root = 1,
  Child = 2,
  PermitAnonymous = 4,
  Public = 8,
  Copies = 16,
  OrganizationSpecific = 32,
  HasAddresses = 64,
  HasPhones = 128,
  HasEmails = 256,
  HasContacts = 512,
  HasDueDate = 1024
}

export interface ActivityDefinition extends ProjectResource {
  activity_definition_id: string;
  type: ActivityType;
  title: string;
  instructions: string;
  sctid: string | null;
  sctpt: string | null;
  start_date: string;
  end_date: string;
  duration_days: number;
  default_status: string;
  flags: ActivityDefinitionFlags;
  start_date_description: string;
  end_date_description: string;
  due_date_description: string;
  title_description: string;
  addresses_description: string;
  phones_description: string;
  emails_description: string;
  contacts_description: string;
  readonly_expression: Expression | null;
  readonly_message: string;
}

export interface UpdateActivityDefinitionArgs
  extends Pick<
    ActivityDefinition,
    | 'flags'
    | 'title'
    | 'instructions'
    | 'duration_days'
    | 'sctid'
    | 'sctpt'
    | 'default_status'
    | 'start_date'
    | 'end_date'
    | 'start_date_description'
    | 'end_date_description'
    | 'due_date_description'
    | 'title_description'
    | 'addresses_description'
    | 'phones_description'
    | 'emails_description'
    | 'contacts_description'
    | 'readonly_message'
  > {
  attachments?: { attachment_id: string }[];
  parent_activity_definitions?: { activity_definition_id: string }[];
  assignable_organizations?: { organization_id: string }[];
  readonly_expression: string;
}

export type CreateActivityDefinitionArgs = UpdateActivityDefinitionArgs &
  Pick<ActivityDefinition, 'type' | 'activity_definition_id'> & {
    copy_activity_definition_id?: string;
  };

export function activityStatusToFHIRServiceRequestStatus(status: string) {
  switch (status) {
    case 'draft':
      return 'draft';
    case 'ready':
    case 'in-progress':
      return 'active';
    case 'completed':
      return 'completed';
    case 'on-hold':
      return 'on-hold';
    case 'cancelled':
      return 'revoked';
    default:
      throw new Error(`Unexpected activity status: ${status}`);
  }
}

export function fhirQuestionnaireResponseStatusToActivityStatus(
  status: string
) {
  switch (status) {
    // non-standard
    case 'draft':
    case 'ready':
    case 'on-hold':
    case 'cancelled':
      return [status];
    // standard
    case 'entered-in-error':
      return ['cancelled'];
    case 'in-progress':
      return ['draft', 'ready', 'in-progress'];
    case 'stopped':
      return ['on-hold'];
    case 'completed':
      return ['completed'];
    default:
      return [];
  }
}

export function fhirServiceRequestStatusToActivityStatus(status: string) {
  switch (status) {
    // non-standard
    case 'ready':
    case 'in-progress':
    case 'cancelled':
      return [status];
    // standard
    case 'draft':
      return ['draft'];
    case 'active':
      return ['in-progress', 'ready'];
    case 'completed':
      return ['completed'];
    case 'on-hold':
      return ['on-hold'];
    case 'revoked':
      return ['cancelled'];
    default:
      return [];
  }
}

export function fhirTaskStatusToActivityStatus(status: string) {
  switch (status) {
    case 'draft':
      return ['draft'];
    case 'ready':
      return ['ready'];
    case 'cancelled':
      return ['cancelled'];
    case 'in-progress':
      return ['in-progress'];
    case 'on-hold':
      return ['on-hold'];
    case 'completed':
      return ['completed'];
    default:
      return [];
  }
}

export function fhirEncounterStatusToActivityStatus(status: string) {
  switch (status) {
    // non-standard
    case 'draft':
    case 'ready':
      return [status];
    // standard
    case 'planned':
      return ['draft'];
    case 'in-progress':
      return ['in-progress'];
    case 'cancelled':
    case 'discontinued':
    case 'entered-in-error':
      return ['cancelled'];
    case 'active':
      return ['in-progress'];
    case 'on-hold':
      return ['on-hold'];
    case 'discharged':
    case 'completed':
      return ['completed'];
    default:
      return [];
  }
}

export function fhirEpisodeStatusToActivityStatus(status: string) {
  switch (status) {
    // non-standard
    case 'draft':
    case 'ready':
    case 'in-progress':
    case 'completed':
      return [status];
    // standard
    case 'planned':
      return ['draft'];
    case 'waitlist':
      return ['ready'];
    case 'entered-in-error':
    case 'cancelled':
      return ['cancelled'];
    case 'active':
      return ['in-progress'];
    case 'on-hold':
      return ['on-hold'];
    case 'finished':
      return ['completed'];
    default:
      return [];
  }
}

export const activityStatusColors: Record<string, PrimerStatus> = {
  draft: 'muted',
  ready: 'open',
  'in-progress': 'open',
  completed: 'done',
  'on-hold': 'attention',
  cancelled: 'closed'
  // amended: 'done',
  // stopped: 'closed',
  // 'entered-in-error': 'closed',
  // active: 'open',
  // revoked: 'closed',
  // failed: 'closed',
  // unknown: 'attention'
};

export const activityStatusStateClass: Record<string, string> = {
  draft: 'State--draft',
  ready: 'State--open',
  'in-progress': 'State--open',
  completed: 'State--merged',
  'on-hold': 'color-bg-attention-emphasis color-fg-on-emphasis',
  cancelled: 'State--closed'
};

export const activityStatusIcons: Record<string, string> = {
  draft: issueDraftIcon,
  ready: issueOpenedIcon,
  'in-progress': issueReopenedIcon,
  completed: issueClosedIcon,
  'on-hold': clockIcon,
  cancelled: skipIcon

  // amended: issueClosedIcon,
  // stopped: skipIcon,
  // 'entered-in-error': skipIcon,
  // active: issueReopenedIcon,
  // revoked: skipIcon,
  // failed: xCircleIcon,
  // unknown: questionIcon
};

export const activityNextStatus: Record<string, string> = {
  draft: 'ready',
  ready: 'in-progress',
  'in-progress': 'completed',
  completed: 'in-progress',
  cancelled: 'ready',
  'on-hold': 'in-progress'
};

export const activityStatuses: Status[] = [
  {
    id: 'draft',
    title: loc_draft,
    is_open: true,
    description: loc_draftStatusDesc
  },
  // { id: 'requested', title: 'Requested',is_open: true, reasons: [] },
  // { id: 'received', title: 'Received',is_open: true, reasons: [] },
  // { id: 'accepted', title: 'Accepted',is_open: true, reasons: [] },
  // { id: 'rejected', title: 'Rejected',is_open: true, reasons: [] },
  {
    id: 'ready',
    title: loc_ready,
    is_open: true,
    description: loc_readyStatusDesc
  },
  {
    id: 'in-progress',
    title: loc_inProgress,
    is_open: true,
    description: loc_inProgressStatusDesc
  },
  {
    id: 'completed',
    title: loc_completed,
    is_open: false,
    description: loc_completedStatusDesc
  },
  {
    id: 'cancelled',
    title: loc_cancelled,
    is_open: false,
    description: loc_cancelledStatusDesc
  },
  {
    id: 'on-hold',
    title: loc_onHold,
    is_open: true,
    description: loc_onHoldStatusDesc
  }
  // { id: 'failed', title: 'Failed', is_open: false, reasons: [] },
  // {
  //   id: 'entered-in-error',
  //   title: 'Entered in Error',
  //   is_open: false,
  //   reasons: []
  // }
];

export const openActivityStatuses = activityStatuses
  .filter(x => x.is_open)
  .map(x => x.id);

export const activityStatusTitles = activityStatuses.reduce(
  (p, c) => {
    p[c.id] = c.title;
    return p;
  },
  {} as Record<string, Record<Locale, string>>
);

export interface ActivityDefinitionLookupItem
  extends Pick<
    ActivityDefinition,
    | 'activity_definition_id'
    | 'project_id'
    | 'type'
    | 'title'
    | 'flags'
    | 'duration_days'
    | 'default_status'
    | 'instructions'
    | 'start_date'
    | 'end_date'
    | 'start_date_description'
    | 'end_date_description'
    | 'due_date_description'
    | 'title_description'
    | 'addresses_description'
    | 'phones_description'
    | 'emails_description'
    | 'contacts_description'
    | 'readonly_expression'
    | 'readonly_message'
  > {
  project_name: string;
  project_login: string;
  parents?: { activity_definition_id: string; type: ActivityType }[];
  organizations?: { organization_id: string }[];
  element_count: number;
  has_signature: boolean;
}

export interface ActivityDefinitionLookupItemWithUtilization
  extends ActivityDefinitionLookupItem {
  created_past_30_days?: {
    count: number;
    last_created_datetime: string | null;
  };
  user_created_past_30_days?: {
    count: number;
    last_created_datetime: string | null;
  };
  organizations?: (OrganizationInfo & {
    rooftop_x?: number;
    rooftop_y?: number;
  })[];
}

export type HeadingNumbering =
  | 'none'
  | 'decimal'
  | 'decimal-leading-zero'
  | 'lower-alpha'
  | 'upper-alpha'
  | 'lower-roman'
  | 'upper-roman';

export interface ActivityDefinitionElement {
  index: number;
  level: number;
  type: 'heading' | 'text' | 'property' | 'recommendation' | 'event-activity';
  preview: boolean;
  visibility: Expression | null;
  text: string;
  heading_numbering: HeadingNumbering;
  required: boolean;
  required_expression: Expression | null;
  readonly_expression: Expression | null;
  prefill: boolean;
  copies: boolean;
  property_id: number | null;
  associated_activity_definition_id: string | null;
}

export interface Activity extends ProjectResource, EntityWithStatus {
  activity_id: string;
  activity_definition_id: string;
  project_id: string;
  contract_id: string;

  title: string;
  // subject_organization_id: string | null;
  subject_person_id: string | null;

  assignee_organization_id: string;
  assignee_user_id: string | null;
  assignment_datetime: string;

  due_date: string;
  start_date: string;
  end_date: string | null;
}

export interface UpdateActivityArgs
  extends Pick<
    Activity,
    | 'assignee_organization_id'
    | 'assignee_user_id'
    | 'contract_id'
    | 'due_date'
    | 'start_date'
    | 'end_date'
    | 'status'
  > {
  title?: string;
  comment_body: string;
  responses: Record<string, null | string | number>;
  definition_json: string;
  definition_json_sri: string;
  addresses: Address[];
  phones: Phone[];
  emails: Email[];
  contacts: Contact[];
  subscribers: LookupItem[];
  attachments?: {
    attachment_id: string;
    property_id?: number;
  }[];
  delete_attachments?: {
    attachment_id: string;
  }[];
  recommendations?: {};
  assignee_organization_login: string;
  assignee_user_login: string | null;
}

export interface CreateActivityArgs
  extends UpdateActivityArgs,
    Pick<Activity, 'activity_definition_id' | 'subject_person_id'> {
  activity_id: string;
  associated_activity_id: string | null;
}

export type PublicCreateActivityArgs = Pick<
  CreateActivityArgs,
  | 'activity_definition_id'
  | 'associated_activity_id'
  | 'responses'
  | 'definition_json'
  | 'definition_json_sri'
>;

export type ActivityResponseValue = string | number;

export interface ActivityComment extends SharedResource {
  activity_comment_id: string;
  activity_id: string;
  body: string;
}

export interface ActivityCommentMention {
  activity_comment_id: string;
  user_id: string;
  organization_id: string;

  create_user_id: string;
  create_organization_id: string;
  // create_datetime: string;
}

export interface ActivityRecommendation {
  activity_id: string;
  activity_definition_id: string;

  create_user_id: string;
  create_organization_id: string;
  // create_datetime: string;
}

export interface ActivityAssociation {
  activity_id: string;
  associated_activity_id: string;

  create_user_id: string;
  create_organization_id: string;
  // create_datetime: string;
}

export interface ActivityTimelineAttachment {
  property_id: number;
  user_id: string;
  organization_id: string;
  datetime: string;
}

export interface ActivityAssignmentRule {
  user_id: string | null;
  organization_id: string | null;
  filters: StandardReportFilter[];
  subscribers?: null | LookupItem[];
  description?: string;
}

export interface ParentActivitySummary {
  activity_id: string;
  activity_definition_id: string;
  subject_person_id: string;
  type: ActivityType;
  definition_title: string;
  title: string;
  status: string;
}

export interface ActivityListItem extends Activity {
  definition_title: string;
  type: ActivityType;
  flags: number;

  project_name: string;
  project_login: string;

  subject_organization_login: string | null;
  subject_organization_name: string | null;

  subject_person_name: string | null;
  subject_person_primary_physical: string | null;
  subject_person_primary_phone: string | null;

  primary_physical: string | null;

  assignee_user_name: string;
  assignee_user_login: string;
  assignee_user_avatar_id: string | null;
  assignee_organization_name: string;
  assignee_organization_login: string;
  assignee_organization_avatar_id: string | null;
  assignee_user_is_active: boolean;
  assignee_user_is_ooo: boolean;

  create_user_name: string;
  create_user_login: string;
  create_user_avatar_id: string | null;
  create_organization_name: string;
  create_organization_login: string;
  create_organization_avatar_id: string | null;
  create_user_is_active: boolean;
  create_user_is_ooo: boolean;

  comment_count: number;
  attachment_count: number;
  child_activity_count: number;
}

export interface ActivityPropertyValue {
  activity_id: string;
  property_id: number;
  label: string;
  value: string;
}

export type ActivityPropertyValuesLookup = {
  [activity_id: string]: ActivityPropertyValue[];
};

export const activityListDefaultSort = 'start-asc';
export const activityListPageSize = 30;

export interface ActivityListArgs {
  title?: string;
  status?: string[];
  type?: string[];
  project?: string[];
  definition?: string[];
  assignee_organization?: string[];
  assignee_user?: string[];
  create_organization?: string[];
  create_user?: string[];
  mentions?: string[];
  attachments?: string[];
  attachments_any?: boolean;
  attachments_none?: boolean;
  sort:
    | 'start-asc'
    | 'start-desc'
    | 'end-asc'
    | 'end-desc'
    | 'created-asc'
    | 'created-desc'
    | 'updated-asc'
    | 'updated-desc'
    | 'due-asc'
    | 'definition';
  filter?: boolean;
  start?: {
    operator: ReportFilterComparisonOperator;
    value: string;
  };
}

export const enum ActivityOrganizationAssociationType {
  Creator = 1,
  Assignee = 2,
  Mentioned = 4,
  ProjectSupervisor = 8,
  OrganizationSupervisor = 16,
  Automatic = 32,
  Contract = 64,
  AssociatedActivity = 128,
  Consent = 256,
  ReferencingActivity = 512
}

export const enum ActivityParticipantType {
  Creator = 1,
  Updator = 2,
  Assignee = 4,
  Commentor = 8,
  Mentioned = 16,
  TeamMentioned = 32,
  Manual = 64,
  Automatic = 128
}

export interface UserOrganizationActivity {
  user_id: string;
  organization_id: string;
  activity_id: string;
  type: number;
  subscribed: boolean;
}

export interface OrganizationActivityInfo extends OrganizationInfo {
  type: number;
}

export interface ActivityNotificationRecipient {
  user_id: string;
  organization_id: string;
  type: number;
  subscribed: boolean;
  email: string;
  task_emails: number; // todo: rename
  organization_login: string;
  organization_name: string;
  is_assignee: boolean;
  was_mentioned: boolean;
}

export function activityTypeDisplayName(
  locale: Locale,
  type: ActivityType
): string {
  switch (type) {
    case 'individual-consent':
      return loc_individualConsent[locale];
    case 'individual-encounter':
      return loc_individualEncounter[locale];
    case 'individual-episode':
      return loc_individualEpisode[locale];
    case 'individual-questionnaire':
      return loc_individualQuestionnaire[locale];
    case 'individual-referral':
      return loc_individualReferral[locale];
    case 'individual-task':
      return loc_individualTask[locale];
    case 'organization-agency':
      return loc_agency[locale];
    case 'organization-site':
      return loc_site[locale];
    case 'organization-event':
      return loc_organizationEvent[locale];
    case 'organization-period':
      return loc_period[locale];
    case 'organization-questionnaire':
      return loc_organizationQuestionnaire[locale];
    case 'organization-task':
      return loc_organizationTask[locale];
    default:
      throw new Error(`Unexpected type "${type}".`);
  }
}

export function activityTypeDescription(
  locale: Locale,
  type: ActivityType
): string {
  switch (type) {
    case 'individual-consent':
      return loc_individualConsentDesc[locale];
    case 'individual-encounter':
      return loc_individualEncounterDesc[locale];
    case 'individual-episode':
      return loc_individualEpisodeDesc[locale];
    case 'individual-questionnaire':
      return loc_individualQuestionnaireDesc[locale];
    case 'individual-referral':
      return loc_individualReferralDesc[locale];
    case 'individual-task':
      return loc_individualTaskDesc[locale];
    case 'organization-agency':
      return loc_organizationAgencyDesc[locale];
    case 'organization-site':
      return loc_organizationSiteDesc[locale];
    case 'organization-event':
      return loc_organizationEventDesc[locale];
    case 'organization-period':
      return loc_organizationPeriodDesc[locale];
    case 'organization-questionnaire':
      return loc_organizationQuestionnaireDesc[locale];
    case 'organization-task':
      return loc_organizationTaskDesc[locale];
    default:
      throw new Error(`Unexpected type "${type}".`);
  }
}

export function activitySubscriptionStatusMessage(
  locale: Locale,
  activityType: ActivityType,
  subscriber: UserOrganizationActivity | undefined
) {
  const subscribed = subscriber?.subscribed ?? false;
  const type = subscriber?.type ?? 0;
  const displayType = activityTypeDisplayName(
    locale,
    activityType
  ).toLocaleLowerCase(locale);
  if (!subscriber) {
    return loc_notificationReasonNone[locale](displayType);
  } else if (!subscribed) {
    return loc_notificationReasonUnsubscribed[locale](displayType);
  } else if (type & ActivityParticipantType.Manual) {
    return loc_notificationReasonManual[locale](displayType);
  } else if (type & ActivityParticipantType.Creator) {
    return loc_notificationReasonCreator[locale](displayType);
  } else if (type & ActivityParticipantType.Assignee) {
    return loc_notificationReasonAssignee[locale](displayType);
  } else if (type & ActivityParticipantType.Commentor) {
    return loc_notificationReasonCommentor[locale](displayType);
  } else if (type & ActivityParticipantType.Mentioned) {
    return loc_notificationReasonMentioned[locale](displayType);
  } else if (type & ActivityParticipantType.Updator) {
    return loc_notificationReasonUpdator[locale](displayType);
  } else if (type & ActivityParticipantType.TeamMentioned) {
    return loc_notificationReasonTeamMentioned[locale](displayType);
  } else if (type & ActivityParticipantType.Automatic) {
    return loc_notificationReasonAutomatic[locale](displayType);
  } else {
    throw new Error(`Unexpected subscriber type ${type}`);
  }
}

export const activityEmailFlags = {
  status: 1,
  assignee: 2,
  comment: 4
};

export const activitySubscriptionButtonHtmlId = 'activity-subscription-button';
export const emailNotifyActivityStatusInputHtmlId = 'email-notify-status';
export const emailNotifyActivityAssigneeInputHtmlId = 'email-notify-assignee';
export const emailNotifyActivityCommentInputHtmlId = 'email-notify-comment';

const defaultRequiredExpression: Expression = {
  type: 'boolean',
  ast: {
    type: 'BinaryExpression',
    operator: '==',
    left: {
      type: 'CallExpression',
      arguments: [{ type: 'Identifier', name: 'ACTIVITY_STATUS' }],
      callee: { type: 'Identifier', name: 'VALUE' }
    },
    right: { type: 'Literal', value: 'completed', raw: "'completed'" }
  }
};

export function walkActivityElements(
  elements: ActivityDefinitionElement[],
  scope: ActivityExpressionScope,
  callback: (
    element: ActivityDefinitionElement,
    visible: boolean,
    required: boolean,
    readonly: boolean
  ) => unknown
) {
  let hiddenHeadingLevel = Number.MAX_SAFE_INTEGER;
  let visibleConsecutiveHeadingLevel = Number.MAX_SAFE_INTEGER;
  for (const el of elements) {
    const visible = !(
      el.visibility && !evaluateActivityExpression('bit', el.visibility, scope)
    );
    // console.log(
    //   `Element ${el.index}, level ${el.level}, visible ${visible} - hiddenHeadingLevel ${hiddenHeadingLevel}`
    // );
    if (el.type === 'heading') {
      // when multiple headings of the same level appear consecutively, only one of them has to be visible
      // for the subsequent fields to be visible.
      visibleConsecutiveHeadingLevel =
        visible || el.level === visibleConsecutiveHeadingLevel
          ? el.level
          : Number.MAX_SAFE_INTEGER;

      if (el.level <= hiddenHeadingLevel) {
        hiddenHeadingLevel =
          visible || el.level === visibleConsecutiveHeadingLevel
            ? Number.MAX_SAFE_INTEGER
            : el.level;
        // console.log(`update hiddenHeadingLevel to ${hiddenHeadingLevel}`);
      }
    } else {
      visibleConsecutiveHeadingLevel = Number.MAX_SAFE_INTEGER;
    }

    const required =
      el.required &&
      Boolean(
        evaluateActivityExpression(
          'bit',
          el.required_expression ?? defaultRequiredExpression,
          scope
        )
      );
    const readonly =
      !!el.readonly_expression &&
      Boolean(evaluateActivityExpression('bit', el.readonly_expression, scope));
    const visibleWithHeading = visible && el.level < hiddenHeadingLevel;
    callback(el, visibleWithHeading, required, readonly);
    scope.hasVisiblePredecessors = visibleWithHeading && el.type !== 'heading';
  }
}

export function getNewActivityHref({
  organization_login,
  activityDefinition: { activity_definition_id, type, flags, parents },
  person_id,
  associated_activity_id,
  recommendation = false,
  params = new URLSearchParams()
}: {
  organization_login: string;
  activityDefinition: ActivityDefinitionLookupItem;
  person_id?: string | null;
  associated_activity_id?: string;
  recommendation?: boolean;
  params?: URLSearchParams;
}) {
  const root = (flags & ActivityDefinitionFlags.Root) > 0;
  const child = (flags & ActivityDefinitionFlags.Child) > 0;
  const permitAnonymous = (flags & ActivityDefinitionFlags.PermitAnonymous) > 0;
  params.set('activity_definition_id', activity_definition_id);

  // INDIVIDUAL
  if (isIndividualActivityType(type) && !(!person_id && permitAnonymous)) {
    if (person_id) {
      if (root) {
        return `/${organization_login}/people/${uuidToBase58(person_id)}/activities/new?${params}`;
      } else if (child || (recommendation && associated_activity_id)) {
        if (associated_activity_id) {
          params.set('associated_activity_id', associated_activity_id);
          return `/${organization_login}/people/${uuidToBase58(person_id)}/activities/new?${params}`;
        } else if (parents?.find(x => isIndividualActivityType(x.type))) {
          return `/${organization_login}/people/${uuidToBase58(person_id)}/activities/?${params}`;
        } else {
          return `/${organization_login}/activities/?${params}`;
        }
      } else {
        return '#';
      }
    } else {
      return `/${organization_login}/people/?${params}`;
    }
  }

  // ORGANIZATION (or anonymous)
  if (root) {
    return `/${organization_login}/activities/new?${params}`;
  } else if (child || (recommendation && associated_activity_id)) {
    if (associated_activity_id) {
      params.set('associated_activity_id', associated_activity_id);
      return `/${organization_login}/activities/new?${params}`;
    } else {
      return `/${organization_login}/activities/?${params}`;
    }
  } else {
    return '#';
  }
}

export function getCreatableActivityDefinitions({
  activityDefinitionLookup,
  person_id,
  associated_activity,
  require_associated_person = true,
  require_associated_activity = true
}: {
  activityDefinitionLookup: ActivityDefinitionLookupItem[];
  person_id?: string | null;
  associated_activity?: {
    activity_definition_id: string;
    project_id: string;
    subject_person_id: string | null;
  };
  require_associated_person?: boolean;
  require_associated_activity?: boolean;
}): {
  active: ActivityDefinitionLookupItem[];
  inactive: ActivityDefinitionLookupItem[];
} {
  const associatedActivityDefiniton = activityDefinitionLookup.find(
    x =>
      x.activity_definition_id === associated_activity?.activity_definition_id
  );
  if (associated_activity && !associatedActivityDefiniton) {
    throw new Error(
      `Unable to find associated activity definition with id "${associated_activity.activity_definition_id}".`
    );
  }

  const results = [];

  for (const x of activityDefinitionLookup) {
    const individual = isIndividualActivityType(x.type);

    // individual activity? require person, anonymous, or not require associated.
    if (
      individual &&
      !person_id &&
      (x.flags & ActivityDefinitionFlags.PermitAnonymous) === 0 &&
      require_associated_person
    ) {
      continue;
    }

    // organization activity? ensure person is not set.
    if (!individual && person_id) {
      continue;
    }

    // associated activity provided? ensure child and ensure parents permit the specified associated activity.
    if (
      associated_activity &&
      !(
        x.flags & ActivityDefinitionFlags.Child &&
        associated_activity.project_id === x.project_id &&
        x.parents?.find(
          p =>
            p.activity_definition_id ===
            associated_activity.activity_definition_id
        )
      )
    ) {
      continue;
    }

    // root?
    if ((x.flags & ActivityDefinitionFlags.Root) > 0 && !associated_activity) {
      results.push(x);
      continue;
    }

    // child?
    if ((x.flags & ActivityDefinitionFlags.Child) === 0) {
      continue;
    }

    // child, but no parents?
    if (!x.parents?.length) {
      continue;
    }

    // organization child with individual parent?
    if (
      !isIndividualActivityType(x.type) &&
      x.parents?.find(p => isIndividualActivityType(p.type))
    ) {
      continue;
    }

    // TEMP: individual child with unknown organization parent?
    // Need to enhance activity list....
    if (
      isIndividualActivityType(x.type) &&
      !associated_activity &&
      x.parents?.find(p => !isIndividualActivityType(p.type))
    ) {
      continue;
    }

    // child with associated activity?
    if (
      associated_activity?.project_id === x.project_id &&
      x.parents?.find(
        y =>
          y.activity_definition_id ===
          associated_activity.activity_definition_id
      )
    ) {
      results.push(x);
      continue;
    }

    // child without associated activity?
    if (!associated_activity && !require_associated_activity) {
      results.push(x);
      continue;
    }
  }

  const today = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
  )
    .toISOString()
    .substr(0, 10);

  const active = [];
  const inactive = [];

  for (const x of results) {
    if (x.start_date <= today && x.end_date >= today) {
      active.push(x);
    } else {
      inactive.push(x);
    }
  }

  return { active, inactive };
}

export function useRefer(
  activityDefinitionLookup: ActivityDefinitionLookupItem[]
) {
  let count = 0;
  for (const x of activityDefinitionLookup) {
    if (x.type === 'individual-referral') {
      count++;
      if (count > 4) {
        return true;
      }
    }
  }
  return false;
}

export interface ActivityValidationRule {
  expression: Expression;
  message: string;
  property_id: number | null;
}

export interface ActivityValidationRules {
  version: 1;
  rules: ActivityValidationRule[];
  property_references: number[];
}
